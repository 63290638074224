import {
  Radio,
  Checkbox,
  TimePicker,
  DatePicker,
  Upload,
  message,
  Button,
  Select,
  Input,
  Modal,
  Spin,
  Tooltip,
  Form,
} from "antd";
import React, { Fragment, useEffect, useRef, useState, useCallback } from "react";
import { UploadOutlined, FileImageOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import Grid from "@material-ui/core/Grid";
import { FILE_UPLOAD, DELETE_FILE, GET_MAP_LIST, GENERATE_PRESIGNED_URL } from "../../graphql/modules";
import SignatureCanvas from "react-signature-canvas";
import { fabric } from "fabric";
import { formatImage } from "../../util";
import { deleteButtonToolTip } from "../../config";

import moment from "moment";
import { UltimateTextToImage } from "ultimate-text-to-image";
import mergeImages from "merge-base64";
import { get } from "lodash";

const { Search } = Input;

const { RangePicker } = DatePicker;

export const PermitFormItem = ({ templateKey, section, item, state, onChange = () => {}, isParent, siteID }) => {
  const [fileList, setFileList] = useState([]);
  const [mapList, setMapList] = useState([]);
  const [FileUpload, { loading: uploadLoading }] = useMutation(FILE_UPLOAD);
  const [DeleteFile, { loading: deleteLoading }] = useMutation(DELETE_FILE);
  const [signModal, setSignModal] = useState(false);
  const [fromParent, setFromParent] = useState(isParent);
  const [childState, setChildState] = useState(state);
  const signatureRef = useRef();

  const [checkedList, setCheckedList] = useState([]);
  const [isSpinHidden, setIsSpinHidden] = useState(true);

  const [visible, setVisible] = useState(false);
  const [mapModel, setMapModal] = useState(false);
  const mapRef = useRef();
  const [trimmedDataURL, setTrim] = useState(null);
  const [value, setValue] = useState(siteID);
  const [limit] = useState(1000);
  const [page, setPage] = useState(1);
  const [mapName, setMapName] = useState("");
  const [canvas, setCanvas] = useState("");
  const [clearCanvas, setCanvasClear] = useState(null);

  const [selectedMapUrl, setSelectedMapUrl] = useState();

  const [getPresignedUrl, {data: PreSignedUrlData }] = useLazyQuery(GENERATE_PRESIGNED_URL);
  const [getMapPresignedUrl] = useLazyQuery(GENERATE_PRESIGNED_URL, {
    variables: {
      filename: mapName,
    },
    onCompleted: (data) => {
      const presignedUrl = get(data, 'GeneratePresignedUrl', {});
      onChangeMapName(presignedUrl?.url)
    }
  });

  const { data, loading, error, refetch } = useQuery(GET_MAP_LIST, {
    variables: {
      limit,
      offset: page - 1,
    },
  });

  const handleData = data?.GetMap?.results?.filter((filterData) => {
    try {
      if (siteID === "") {
        return filterData;
      } else {
        return filterData.site.toLowerCase().indexOf(siteID) !== -1;
      }
    } catch (err) {
      console.error(err);
    }
  });

  function handleChangeMapName(value) {
    setMapName(value);
    getMapPresignedUrl()
  }

  async function onChangeMapName(mapImage) {
    //if (clearCanvas !== "") {
    //  try {
    //    clearCanvas.dispose();
    //  } catch (e) {
    //    console.error("onChangeMapName catch: " + e);
    //    console.error("msg: no canvas to dispose");
    //  }
    //}
    const c = mapRef.current;
    const canvas = new fabric.Canvas(c, {
      isDrawingMode: true,
    });
    canvas.freeDrawingBrush.color = "red";
    canvas.freeDrawingBrush.width = 2;
    canvas.setHeight(600);
    canvas.setWidth(1000);
    try {
      fabric.Image.fromURL(
        mapImage,
        function (myImg) {
          canvas.setBackgroundImage(myImg, canvas.renderAll.bind(canvas), {
            // width: canvas.width,
            // height: canvas.height,
            // originX: "left",
            // originY: "top",
            scaleX: canvas.width / myImg.width,
            scaleY: canvas.height / myImg.height,
          });
        },
        { crossOrigin: "anonymous" }
      );
  
      canvas.renderAll();
      setCanvasClear(canvas);
    } catch (e) {
      console.error("onChangeMapName catch: " + e);
      console.error("msg: no canvas to dispose or clear");
    }
  }

  useEffect(() => {
    setFromParent(isParent);
  }, [isParent]);

  useEffect(() => {
    // console.log("User is selecting from checkboxes...");
  }, [checkedList]);

  const onChildChange = async (obj, itemName) => {
    setChildState({ ...childState, ...obj });
    onChange(obj);
  };

  const onSaveSignature = async () => {
    var canvas = signatureRef.current;

    var today = new Date();
    var todayUTCText = today.toLocaleString("en-SG");
    const textToImage = new UltimateTextToImage(todayUTCText, {
      width: 500,
      height: 200,
      alignToCenterIfLinesLE: 1,
      fontSize: 20,
    }).render();
    const dataUrlPng = textToImage.toDataUrl();

    const dataUrl = canvas.toDataURL("image/png");

    let subStr = "data:image/png;base64,";
    var signUrl = dataUrl.substring(subStr.length);
    var dateUrl = dataUrlPng.substring(subStr.length);

    const mergedImage = await mergeImages([signUrl, dateUrl], {
      align: "center",
      color: "rgba(255,255,255,1)",
      offset: -500,
    });
    var convertedMergedImg = mergedImage.replace("data:image/jpeg", "data:image/png");

    onChildChange({ [item.name]: convertedMergedImg }, item.name);
    // setSignModal(false);
  };

  const onChangeSignatureDate = async () => {
    // OnSaveSignatureAddDate();
    setSignModal(false);
  };

  const OnSaveSignatureAddDate = async () => {
    console.log("OnSaveSignatureAddDate signNameDate: " + item?.name);

    try {
      if (item?.name?.includes("sign_")) {
        var dateSigned = item?.name.replace("sign_", "date_");
        var formattedDateToday = moment(new Date()).format("YYYY-MM-DD");

        console.log("OnSaveSignatureAddDate dateSigned: " + dateSigned);
        console.log("OnSaveSignatureAddDate formattedDateToday: " + formattedDateToday);

        var el = document.getElementById(dateSigned);
        el.value = formattedDateToday;
        doEvent(el, "input", dateSigned, formattedDateToday);
      }
    } catch (e) {
      console.error("error on OnSaveSignatureAddDate: " + e);
    }
  };

  const doEvent = async (obj, event, field, value) => {
    var eve = new Event(event, { target: obj, bubbles: true });
    onChildChange({ [field]: value }, field);
    return obj ? obj.dispatchEvent(eve) : false;
  };

  const clear = () => {
    signatureRef.current.clear();
    onChildChange({ [item.name]: undefined });
  };
  const clearMap = () => {
    if(clearCanvas) {
      clearCanvas.remove(...clearCanvas.getObjects());
    }
  };

  const onSaveMap = async () => {
    clearCanvas.getElement().toBlob(async (blob) => {
      let file = new File([blob], "mapDemacartion.png", { type: "image/png" });
      console.log("file: " + JSON.stringify(file, null, 2));
      try {
        const {
          data: { SingleUpload },
        } = await FileUpload({
          variables: {
            file,
          },
        });
        file.url = SingleUpload?.url;
        file.filename = SingleUpload?.filename;
        // onChange({[item.name]: [...item.name, SingleUpload?.filename] });
        // onChange({...fileList, [item.name]: file.filename });
        onChange({ [item.name]: [...(state[item.name] || []), SingleUpload?.filename] });
        setMapModal(false);
      } catch (error) {
        console.log(error);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
        setIsSpinHidden(true);
      }
    }, "image/jpeg");

    setSignModal(false);
    return;
  };

  /**  START GET UPLOADED FILES */
  const fetchPresignedUrl = async (filename) => {
    // console.log("fetchPresignedUrl filename: " + filename);
    try {
      getPresignedUrl({ variables: { filename: filename } });
    } catch (error) {
      // console.error("Error fetching pre-signed URL:", error);
      return null;
    }
  };

  const handleGetPresignedUrlData = async (filename) => {
    console.log("handleGetPresignedUrlData: " + filename);
    fetchPresignedUrl(filename);
  };

  useEffect(() => {
    // console.log("useEffect PreSignedUrlData: " + JSON.stringify(PreSignedUrlData, null, 2));
    if(PreSignedUrlData && PreSignedUrlData?.GeneratePresignedUrl?.success) {
      const newWindow = window.open(PreSignedUrlData?.GeneratePresignedUrl?.url, '_blank');
      if (!newWindow) {
        console.error("Failed to open new window. It might be blocked by the browser.");
      }
    }
  }, [PreSignedUrlData]);

  /**  END GET UPLOADED FILES */

  const props = {
    name: "file",
    uid: "fileid",
    customRequest: async ({ file, onSuccess }) => {
      console.log("file :" + JSON.stringify(file, null, 2));
      try {
        const {
          data: { SingleUpload },
        } = await FileUpload({
          variables: {
            file,
          },
        });
        let changeFileName = SingleUpload?.filename.replace(/[^a-zA-Z0-9]/g, "-");
        file.url = SingleUpload?.url;
        file.filename = changeFileName;
        onSuccess("done");
      } catch (error) {
        onChange({ [item.name]: [] });
        setFileList([]);

        console.log("customRequest");
        console.log(error);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
        setIsSpinHidden(true);
      }
    },
    onPreview: async (file) => {
      handleGetPresignedUrlData(file.filename);
    },
    showUploadList: {
      showRemoveIcon: !item?.required,
    },
    onRemove: async (file, event) => {
      try {
        await DeleteFile({
          variables: {
            filename: file.filename,
          },
        });
        console.log(templateKey);

        const files = fileList.filter((f) => f.uid !== file.uid);
        // const stateFiles = state[item.name].filter((f) => f !== file.filename);
        var stateFiles;
        if (state[item.name] !== undefined) {
          stateFiles = state[item.name].filter((f) => f !== file.filename);
        } else {
          stateFiles = state.children[templateKey].submission[item.name].filter((f) => f !== file.filename);
        }

        onChange({ [item.name]: stateFiles });
        setFileList(files);
      } catch (error) {
        console.log("onRemove");
        console.log(error);
      }
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
    },
    fileList,
    // multiple: false,
    listType: "picture",
    onChange(info, list) {
      if (info.file.status === "uploading") {
        setIsSpinHidden(false);
      }

      if (info.file.status === "done") {
        message.success(`File uploaded successfully`);
        onChange({ [item.name]: [...(state[item.name] || []), info.file.filename.replace(/#/g, "_")] });
        setIsSpinHidden(true);
      } else if (info.file.status === "error") {
        setIsSpinHidden(true);
        onChange({ [item.name]: [] });
        setFileList([]);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
      }
    },

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const onCheckItem = (option, e) => {
    let newArr = checkedList;

    if (newArr) {
      if (newArr.some((o) => o === option.value)) {
        if (!e.target.checked) {
          newArr.splice(newArr.indexOf(option.value), 1);
        }
      } else {
        if (e.target.checked) {
          newArr.push(option.value);
        }
      }
    } else {
      newArr = [option.value];
    }

    setCheckedList(newArr);
  };

  let input = JSON.stringify(item);
  let inputProps = {};
  let styleBorderColor = "";

  if (item.required === true && !childState[item.name]) {
    styleBorderColor = "red";
  }

  if (item.type === "textarea") {
    input = (
      <Form.Item name={item?.name} rules={[{ required: item?.required, message: "This field is required." }]}>
        <div>
          {
            item.tooltip ? (
              <Tooltip title={item.tooltipText}>
                <textarea
                  style={{ borderColor: styleBorderColor }}
                  rows={5}
                  type={item.type}
                  className="form-control"
                  placeholder={item.placeholder}
                  name={item.name}
                  onChange={(e) => onChildChange({ [item.name]: e.target.value })}
                  required={item.required}
                  {...inputProps}
                  // disabled={value}
                ></textarea>
              </Tooltip>
            ) : (
              <textarea
                style={{ borderColor: styleBorderColor }}
                rows={5}
                type={item.type}
                className="form-control"
                placeholder={item.placeholder}
                name={item.name}
                onChange={(e) => onChildChange({ [item.name]: e.target.value })}
                required={item.required}
                {...inputProps}
                // disabled={value}
              ></textarea>
            )
          }
        </div>
      </Form.Item>
    );
  } else if (item.type === "select") {
    input = (
        item.tooltip ? (
          <Tooltip title={item.tooltipText}>
            <Select
              placeholder={item.label}
              style={{ width: "100%", borderColor: styleBorderColor }}
              bordered
              type="name"
              size="large"
              className="form-control-select"
              name="label"
              onChange={(value) => onChildChange({ [item.name]: value })}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              // value={childState[item.value] !== undefined ? childState[item.value] : item.defaultValue}
              required={item.required}
              {...inputProps}
            >
              {item?.options?.map((option, key) => (
                <Select.Option key={key} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Tooltip>
        ) : (
          <Select
            placeholder={item.label}
            style={{ width: "100%", borderColor: styleBorderColor }}
            bordered
            type="name"
            size="large"
            className="form-control-select"
            name="label"
            onChange={(value) => onChildChange({ [item.name]: value })}
            showSearch
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            // value={childState[item.value] !== undefined ? childState[item.value] : item.defaultValue}
            required={item.required}
            {...inputProps}
          >
            {item?.options?.map((option, key) => (
              <Select.Option key={key} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        )
    );
  } else if (item.type === "radio") {
    input = (
      item.tooltip ? (
        <Tooltip title={item.tooltipText}>
          <Fragment>
            <label className="w-100">{item.label}</label>
            <Radio.Group
              style={{ borderColor: styleBorderColor }}
              name={item.name}
              onChange={(e) => onChildChange({ [item.name]: e.target.value })}
              // value={state[item.name]}
              required={item.required}
              {...inputProps}
            >
              {item?.options?.map((option, key) => (
                <Radio style={radioStyle} value={option.value} key={key}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Fragment>
        </Tooltip>
      ) : (
        <Fragment>
          <label className="w-100">{item.label}</label>
          <Radio.Group
            style={{ borderColor: styleBorderColor }}
            name={item.name}
            onChange={(e) => onChildChange({ [item.name]: e.target.value })}
            // value={state[item.name]}
            required={item.required}
            {...inputProps}
          >
            {item?.options?.map((option, key) => (
              <Radio style={radioStyle} value={option.value} key={key}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
      </Fragment> )
    );
  } else if (item.type === "checkbox") {
    input = ( item.tooltip ? (
      <Tooltip title={item.tooltipText}>
        <div className="form-group">
          <Checkbox
            style={{ borderColor: styleBorderColor }}
            onChange={(e) => {
              onChildChange({ [item.name]: e.target.checked });
            }}
            // checked={state[item.name]}
            required={item.required}
          >
            {item.label}
          </Checkbox>
        </div> 
      </Tooltip>
    ) : (
        <div className="form-group">
        <Checkbox
          style={{ borderColor: styleBorderColor }}
          onChange={(e) => {
            onChildChange({ [item.name]: e.target.checked });
          }}
          // checked={state[item.name]}
          required={item.required}
        >
          {item.label}
        </Checkbox>
      </div>
      )
    );
  } else if (item.type === "multicheck") {
    input = ( item.tooltip ? (
      <Tooltip title={item.tooltipText}>
        <div className="">
          <label htmlFor="required" className="cursor-pointer">
            {item.label}
          </label>
          {item?.options?.map((option, index) => {
            return ( item.tooltip ? (
              <Checkbox
                key={index}
                style={radioStyle}
                onChange={(e) => {
                  e.preventDefault();
                  if (fromParent) {
                    // console.log("Multi Check Change from Parent Permit");
                    onCheckItem(option, e);
                    // console.log("before onChange" + checkedList);
                    onChange({ [item.name]: checkedList });
                  } else if (!fromParent) {
                    // console.log("Multi Check Change from Child Permit");
                    /** OLD WORKING CODE FOR CHILD PERMIT */
                    let values = checkedList;

                    if (values) {
                      if (values.some((o) => o === option.value)) {
                        if (!e.target.checked) {
                          values.splice(values.indexOf(option.value), 1);
                        }
                      } else {
                        if (e.target.checked) {
                          values.push(option.value);
                        }
                      }
                    } else {
                      values = [option.value];
                    }

                    setCheckedList(values);
                    onChildChange({ [item.name]: checkedList });
                    /** OLD WORKING CODE FOR CHILD PERMIT */
                  }
                }}
                checked={checkedList?.includes(option.value)}
                // required={item.required}
              >
                {option.label}
              </Checkbox> ) : (
                <Checkbox
                key={index}
                style={radioStyle}
                onChange={(e) => {
                  e.preventDefault();
                  if (fromParent) {
                    // console.log("Multi Check Change from Parent Permit");
                    onCheckItem(option, e);
                    // console.log("before onChange" + checkedList);
                    onChange({ [item.name]: checkedList });
                  } else if (!fromParent) {
                    // console.log("Multi Check Change from Child Permit");
                    /** OLD WORKING CODE FOR CHILD PERMIT */
                    let values = checkedList;

                    if (values) {
                      if (values.some((o) => o === option.value)) {
                        if (!e.target.checked) {
                          values.splice(values.indexOf(option.value), 1);
                        }
                      } else {
                        if (e.target.checked) {
                          values.push(option.value);
                        }
                      }
                    } else {
                      values = [option.value];
                    }

                    setCheckedList(values);
                    onChildChange({ [item.name]: checkedList });
                    /** OLD WORKING CODE FOR CHILD PERMIT */
                  }
                }}
                checked={checkedList?.includes(option.value)}
                // required={item.required}
              >
                {option.label}
              </Checkbox>
              )
            );
          })}
        </div>
      </Tooltip>
    ) : (
      <div className="">
        <label htmlFor="required" className="cursor-pointer">
          {item.label}
        </label>
        {item?.options?.map((option, index) => {
          return ( item.tooltip ? (
            <Checkbox
              key={index}
              style={radioStyle}
              onChange={(e) => {
                e.preventDefault();
                if (fromParent) {
                  // console.log("Multi Check Change from Parent Permit");
                  onCheckItem(option, e);
                  // console.log("before onChange" + checkedList);
                  onChange({ [item.name]: checkedList });
                } else if (!fromParent) {
                  // console.log("Multi Check Change from Child Permit");
                  /** OLD WORKING CODE FOR CHILD PERMIT */
                  let values = checkedList;

                  if (values) {
                    if (values.some((o) => o === option.value)) {
                      if (!e.target.checked) {
                        values.splice(values.indexOf(option.value), 1);
                      }
                    } else {
                      if (e.target.checked) {
                        values.push(option.value);
                      }
                    }
                  } else {
                    values = [option.value];
                  }

                  setCheckedList(values);
                  onChildChange({ [item.name]: checkedList });
                  /** OLD WORKING CODE FOR CHILD PERMIT */
                }
              }}
              checked={checkedList?.includes(option.value)}
              // required={item.required}
            >
              {option.label}
            </Checkbox> ) : (
              <Checkbox
              key={index}
              style={radioStyle}
              onChange={(e) => {
                e.preventDefault();
                if (fromParent) {
                  // console.log("Multi Check Change from Parent Permit");
                  onCheckItem(option, e);
                  // console.log("before onChange" + checkedList);
                  onChange({ [item.name]: checkedList });
                } else if (!fromParent) {
                  // console.log("Multi Check Change from Child Permit");
                  /** OLD WORKING CODE FOR CHILD PERMIT */
                  let values = checkedList;

                  if (values) {
                    if (values.some((o) => o === option.value)) {
                      if (!e.target.checked) {
                        values.splice(values.indexOf(option.value), 1);
                      }
                    } else {
                      if (e.target.checked) {
                        values.push(option.value);
                      }
                    }
                  } else {
                    values = [option.value];
                  }

                  setCheckedList(values);
                  onChildChange({ [item.name]: checkedList });
                  /** OLD WORKING CODE FOR CHILD PERMIT */
                }
              }}
              checked={checkedList?.includes(option.value)}
              // required={item.required}
            >
              {option.label}
            </Checkbox>
            )
          );
        })}
      </div>
    )
    );
  } else if (item.type === "time") {
    input = ( item.tooltip ? (
      <Tooltip title={item.tooltipText}>
        <div className="">
          <TimePicker
            style={{ borderColor: styleBorderColor }}
            className="form-control"
            placeholder={item.label}
            // value={moment(childState[item.name] || "00:00", "HH:mm")}
            format={"HH:mm"}
            onChange={(_moment, timestring) => onChildChange({ [item.name]: timestring })}
            required={item.required}
          />
        </div> 
      </Tooltip>
      ) : (
        <TimePicker
          style={{ borderColor: styleBorderColor }}
          className="form-control"
          placeholder={item.label}
          // value={moment(childState[item.name] || "00:00", "HH:mm")}
          format={"HH:mm"}
          onChange={(_moment, timestring) => onChildChange({ [item.name]: timestring })}
          required={item.required}
        />
      )
    );
  } else if (item.type === "date") {
    input = ( item.tooltip ? (
      <Tooltip title={item.tooltipText}>
        <div className="" id={item?.name}>
          <Form.Item
            name={item?.name}
            dependencies={[item?.name]}
            rules={[
              {
                validator: async (_, value) => {
                  console.log("validator value: " + value);

                  if (item?.required) {
                    if (childState[item?.name] === null) {
                      return Promise.reject("This signature field is required.");
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div>
              <DatePicker
                style={{ borderColor: styleBorderColor }}
                className="form-control"
                placeholder={item.label}
                onChange={(_moment, timestring) => onChildChange({ [item.name]: timestring })}
                // value={childState[item.name] !== undefined ? moment(childState[item.name]) : undefined}
                required={item.required}
              />
            </div>
          </Form.Item>
        </div>
      </Tooltip>
    ) : (
      <div className="" id={item?.name}>
      <Form.Item
        name={item?.name}
        dependencies={[item?.name]}
        rules={[
          {
            validator: async (_, value) => {
              console.log("validator value: " + value);

              if (item?.required) {
                if (childState[item?.name] === null) {
                  return Promise.reject("This signature field is required.");
                }
                return Promise.resolve();
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <div>
          <DatePicker
            style={{ borderColor: styleBorderColor }}
            className="form-control"
            placeholder={item.label}
            onChange={(_moment, timestring) => onChildChange({ [item.name]: timestring })}
            // value={childState[item.name] !== undefined ? moment(childState[item.name]) : undefined}
            required={item.required}
          />
        </div>
      </Form.Item>
      </div>
    )
    );
  } else if (item.type === "daterange") {
    input = ( item.tooltip ? (
      <Tooltip title={item.tooltipText}>
      <div>
        <RangePicker
          style={{ borderColor: styleBorderColor }}
          className="form-control"
          onChange={(_moment, timestring) => {
            // console.log(timestring, state);
            onChildChange({ [item.name]: timestring });
          }}
          // value={state[item.name]?.length ? [moment(state[item.name][0]), moment(state[item.name][1])] : []}
          required={item.required}
        />
      </div>
      </Tooltip>
      ) : (
        <div>
        <RangePicker
          style={{ borderColor: styleBorderColor }}
          className="form-control"
          onChange={(_moment, timestring) => {
            // console.log(timestring, state);
            onChildChange({ [item.name]: timestring });
          }}
          // value={state[item.name]?.length ? [moment(state[item.name][0]), moment(state[item.name][1])] : []}
          required={item.required}
        />
      </div>
      )
    );
  } else if (item.type === "file") {
    input = ( item.tooltip ? (
      <Tooltip title={item.tooltipText}>
        <div id="PermitUpload">
          <Tooltip placement="topRight" title={deleteButtonToolTip}>
            <Form.Item
              name={item?.name}
              dependencies={[item?.name]}
              rules={[
                {
                  validator: async (_, value) => {
                    if (item?.required) {
                      if (Array.isArray(fileList) && !fileList.length) {
                        return Promise.reject("This file is required.");
                      }
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div>
                <Upload {...props} className="w-100 d-block" fileList={fileList}>
                  <Button className="form-control" icon={<UploadOutlined />}>
                    {item.placeholder || "Click to upload"}
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </Tooltip>
          <div hidden={isSpinHidden} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spin tip="Uploading..."></Spin>
          </div>
        </div>
      </Tooltip>
      ) : (
        <div id="PermitUpload">
          <Tooltip placement="topRight" title={deleteButtonToolTip}>
            <Form.Item
              name={item?.name}
              dependencies={[item?.name]}
              rules={[
                {
                  validator: async (_, value) => {
                    if (item?.required) {
                      if (Array.isArray(fileList) && !fileList.length) {
                        return Promise.reject("This file is required.");
                      }
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <div>
                <Upload {...props} className="w-100 d-block" fileList={fileList}>
                  <Button className="form-control" icon={<UploadOutlined />}>
                    {item.placeholder || "Click to upload"}
                  </Button>
                </Upload>
              </div>
            </Form.Item>
          </Tooltip>
          <div hidden={isSpinHidden} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Spin tip="Uploading..."></Spin>
          </div>
        </div>
      )
    );
  } else if (item.type === "esign") {
    // const disableSign = user.role !== UserRole.ContractorPM && user.role !== UserRole.MTGroup;
    input = ( item.tooltip ? (
      <Tooltip title={item.tooltipText}>
        <div className="" id="PermitUpload">
          <Form.Item
            name={item?.name}
            dependencies={[item?.name]}
            rules={[
              {
                validator: async (_, value) => {
                  console.log("validator value: " + value);

                  if (item?.required) {
                    if (childState[item?.name] === null) {
                      return Promise.reject("This signature field is required.");
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <div>
              <Button
                // disabled={disableSign}
                icon={<UploadOutlined />}
                className="form-control"
                size="large"
                onClick={() => setSignModal(true)}
              >
                {item.label}
              </Button>
            </div>
            {childState[item.name] && <p className="text-info mt-1">Signature added</p>}
            <Modal
              visible={signModal}
              onCancel={() => setSignModal(false)}
              title="Draw Signature"
              onOk={onChangeSignatureDate}
              okText="Save"
            >
              <div style={{ overflow: "hidden" }}>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                    style: { background: "rgba(0,0,0,0)" },
                  }}
                  onEnd={onSaveSignature}
                  ref={signatureRef}
                />
              </div>
              <button className="form-control" onClick={clear}>
                Clear{" "}
              </button>
            </Modal>
          </Form.Item>
        </div>
      </Tooltip>
    ) : (
      <div className="" id="PermitUpload">
      <Form.Item
        name={item?.name}
        dependencies={[item?.name]}
        rules={[
          {
            validator: async (_, value) => {
              console.log("validator value: " + value);

              if (item?.required) {
                if (childState[item?.name] === null) {
                  return Promise.reject("This signature field is required.");
                }
                return Promise.resolve();
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <div>
          <Button
            // disabled={disableSign}
            icon={<UploadOutlined />}
            className="form-control"
            size="large"
            onClick={() => setSignModal(true)}
          >
            {item.label}
          </Button>
        </div>
        {childState[item.name] && <p className="text-info mt-1">Signature added</p>}
        <Modal
          visible={signModal}
          onCancel={() => setSignModal(false)}
          title="Draw Signature"
          onOk={onChangeSignatureDate}
          okText="Save"
        >
          <div style={{ overflow: "hidden" }}>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 500,
                height: 200,
                className: "sigCanvas",
                style: { background: "rgba(0,0,0,0)" },
              }}
              onEnd={onSaveSignature}
              ref={signatureRef}
            />
          </div>
          <button className="form-control" onClick={clear}>
            Clear{" "}
          </button>
        </Modal>
      </Form.Item>
      </div>
    )
    );
  } else if (item.type === "map") {
    input = (
      <div className="" id="PermitUpload">
        {
          item.tooltip ? (
            <Tooltip title={item.tooltipText}>
              <Button
                icon={<FileImageOutlined />}
                className="form-control"
                size="large"
                onClick={() => {
                  setMapModal(true);
                }}
              >
                {item.label}
              </Button>
            </Tooltip>
          ) : (
            <Button
                icon={<FileImageOutlined />}
                className="form-control"
                size="large"
                onClick={() => {
                  setMapModal(true);
                }}
              >
                {item.label}
              </Button>
          )
        }
        {Array.isArray(state[item.name])
          ? state[item.name].map((filename) => {
              if (
                filename.split(".").pop() === "jpg" ||
                filename.split(".").pop() === "png" ||
                filename.split(".").pop() === "jpeg"
              ) {
                return (
                  <img
                    alt="state"
                    width="100"
                    src={formatImage(filename)}
                    onClick={() => setVisible(filename)}
                    style={{ cursor: "pointer" }}
                    className="mr-2 mb-2"
                  />
                );
              } else {
                return (
                  <div>
                    <a href="#" 
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        handleGetPresignedUrlData(filename);
                      }}
                    >
                      <span>{filename}</span>
                    </a>
                    <hr />
                  </div>
                );
              }
            })
          : "N/A"}
        {item.type === "map" && (
          <Modal
            width={1000}
            title={item.label}
            onCancel={() => setVisible(false)}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  try {
                    DeleteFile({
                      variables: {
                        filename: visible,
                      },
                    });
                    const stateFiles = state[item.name].filter((f) => f !== visible);
                    onChange({ [item.name]: stateFiles });
                    setVisible(false);
                  } catch (error) {
                    console.log("onRemove");
                    console.log(error);
                  }
                }}
              >
                Delete
              </Button>,
              <Button key="back" type="primary" onClick={() => setVisible(false)}>
                Ok
              </Button>,
            ]}
            visible={visible}
          >
            <img alt="state" src={formatImage(visible)} style={{ width: "100%" }} />
          </Modal>
        )}

        <Modal
          visible={mapModel}
          onCancel={() => setMapModal(false)}
          title="Map"
          onOk={onSaveMap}
          okText="Save"
          width={1100}
        >
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={6}>
              <Select style={{ width: "100%" }} placeholder="Select Map" 
                onChange={handleChangeMapName}
              >
                {handleData?.map((_map) => (
                  <Select.Option key={_map._id} value={_map.mapImage}>
                    {_map.name}
                  </Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1%" }}>
              <canvas ref={mapRef} style={{ width: "100%" }} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "1%" }}>
              <button className="form-control" onClick={clearMap}>
                Clear
              </button>
            </Grid>
          </Grid>
          {/* <Fragment style={{ width: "100%" }}>
            <div className="row">
              <div className="form-group col-md-6"></div>
            </div>
          </Fragment> */}
        </Modal>
      </div>
    );
  } else if (item.type === "freetext") {
    input = <div className="w-100">{item.label}</div>;
  } else {
    input = (
      <Form.Item name={item?.name} rules={[{ required: item?.required, message: "This field is required." }]}>
        <div>
          {
            item.tooltip ? (
              <Tooltip title={item.tooltipText}>
                <input
                  style={{ borderColor: styleBorderColor }}
                  className="form-control"
                  placeholder={item.placeholder}
                  name={item.name}
                  onChange={(e) => onChildChange({ [item.name]: e.target.value })}
                  required={item.required}
                  {...inputProps}
                />
              </Tooltip>
            ) : (
              <input
                style={{ borderColor: styleBorderColor }}
                // type={item.type}
                className="form-control"
                placeholder={item.placeholder}
                name={item.name}
                onChange={(e) => onChildChange({ [item.name]: e.target.value })}
                required={item.required}
                {...inputProps}
              />
            )
          }
        </div>
      </Form.Item>
    );
  }
  return (
    <div id="permit-input" className={`form-group col-md-${item.col}`}>
      {input}
      {item.required && <small className="text-danger d-block">Required</small>}
    </div>
  );
};

const radioStyle = {
  display: "block",
  // height: "30px",
  lineHeight: "30px",
  fontWeight: "normal",
  marginLeft: 0,
  marginBottom: 15,
};
