import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";

import { GET_BLOCKED_WORDS_LIST, DELETE_BLOCKED_WORD, CREATE_BLOCKED_WORD} from "../graphql/modules/blockedwords";
import { Pagination, Popconfirm, Button, Modal, message} from "antd";
import { DynamicList } from "../components/Shared";
import { DeleteOutlined } from "@ant-design/icons";


export const AllBlockedWords = ({ match }) => {
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newWord, setNewWord] = useState('');

  const { data, loading, error, refetch } = useQuery(GET_BLOCKED_WORDS_LIST);

  const [ AddBlockedWord ] = useMutation(CREATE_BLOCKED_WORD);

  const onChangePagination = (p, size) => {
    setPage(p);
    setLimit(size)
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    // Handle form submission to add new blocked word
    try {
      const { data } = await AddBlockedWord({ 
        variables: { 
          data: {
            _word: newWord
          } 
        } 
      });

      if(!data?.AddBlockedWord?.success) {
        message.error(data?.AddBlockedWord?.message);
        return;
      } else {
        message.success(data?.AddBlockedWord?.message);
        refetch();
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }


    setIsModalVisible(false);
    setNewWord('');
  };

  const onChange = (e) => {
    setNewWord(e.target.value);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const [DeleteWord] = useMutation(DELETE_BLOCKED_WORD);
  const handleDelete = async (id) => {
    await DeleteWord({ variables: { id } });
    refetch();
  };

  const total = data?.GetBlockedWords?.count;
  const results = data?.GetBlockedWords?.results;

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
    },
    {
      title: "Blocked Word",
      dataIndex: "_word",
      key: "_word",
      sorter: (a, b) => a._word.localeCompare(b._word),
      sortDirections: ["descend"],
      defaultSortOrder: 'ascend',
    },
    {
      title: "Action",
      render: (_, record) => (
        <Popconfirm title="Are you sure you want to delete blocked word?" onConfirm={() => handleDelete(record._id)}>
          <DeleteOutlined style={{ color: "#f01" }} />
        </Popconfirm>
      ),
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Add Blocked Word
      </Button>

      <Modal
        title="Add New Blocked Word"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <div className="heading"></div>
          <form onSubmit={handleOk}>
            <div className="card">
              <div className="create-permit-details">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <input type="name" className="form-control" placeholder="Word to block" name="name" onChange={onChange} />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <DynamicList
        title="List of blocked words for Micron Manager Field"
        loading={loading}
        columns={columns}
        data={results}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        }
      />
    </>
  );
};
