import React, { useEffect, useState, Fragment, useRef } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Spin, Select, Modal, Button, Checkbox, Typography } from "antd";
import { UserOutlined, TeamOutlined, ConsoleSqlOutlined } from "@ant-design/icons";
import { Divider } from "@material-ui/core";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
// import _ from "lodash";

import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";

import { PermitSection } from "../components/ReviewPermit";
import { PermitUserInfo } from "../components/Permit/PermitUserInfo";
import { CustomButton } from "../components/Shared";
import { PermitStatus, UserRole } from "../config";
import {
  IsPermitCloser,
  IsUserMTGroupSiteSameWithPermitSite,
  userInApprovedBy,
  getIndividualReviewersOnly,
  getMTGroupApproveCount,
  getIndividualReviewerApproveCount,
  getApproveCountNeeded,
  getNumberOfApproves,
} from "../helpers";
import {
  GET_CHILD_PERMIT,
  UPDATE_CHILD_PERMIT_STATUS,
  UPDATE_CHILD_PERMIT,
  GET_GROUP_LIST,
  GET_ALL_USERS_LIST,
  UPDATE_CHILD_PERMIT_REVIEWERS,
  UPDATE_ON_APPROVED_PERMIT,
  REMOVE_CHILD_INDIVIDUAL_REVIEWER,
  REMOVE_CHILD_MTGROUP_REVIEWER,
  UPDATE_PERMIT_STATUS_APPROVE_LAST_REVIEWER_REMOVED,
  GET_ALL_DISCIPLINE,
  GET_ALL_MICRON_USERS,
} from "../graphql/modules";
import { successNotify, warnNotify } from "../util";

const { Title } = Typography;
const micronEmail = "@micron.com";
export const ReviewChildPermit = ({ match, history }) => {
  const { user: authUser } = useSelector((_state) => _state.auth);
  const formRef = useRef(null);
  const { data, loading, refetch } = useQuery(GET_CHILD_PERMIT, {
    variables: {
      id: match.params.id,
    },
  });

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  const [getGroups, { data: GroupList, loading: fetchingGroup }] = useLazyQuery(GET_GROUP_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_USERS_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getHostOwner, { data: HostOwnerList, loading: fetchingHostOwner }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: micronEmail,
    },
  });

  const [ApprovePermitWhenLastReviewerIsRemoved] = useMutation(UPDATE_PERMIT_STATUS_APPROVE_LAST_REVIEWER_REMOVED);
  const [UpdateChildPermitStatus, { loading: updateStatusLoading }] = useMutation(UPDATE_CHILD_PERMIT_STATUS);
  const [UpdateChildPermit, { loading: updateLoading }] = useMutation(UPDATE_CHILD_PERMIT);
  const [UpdateChildPermitReviewers] = useMutation(UPDATE_CHILD_PERMIT_REVIEWERS);
  const [UpdateApprovedPermit, { loading: updateApprovedPermitLoading }] = useMutation(UPDATE_ON_APPROVED_PERMIT);
  const [UpdateRemoveChildIndividualReviewer] = useMutation(REMOVE_CHILD_INDIVIDUAL_REVIEWER);
  const [UpdateRemoveChildMTGroupReviewer] = useMutation(REMOVE_CHILD_MTGROUP_REVIEWER);

  const [visible, setVisible] = React.useState(false);
  const [reason, setRejectReason] = React.useState("");
  const [state, setState] = useState({});
  const [childrenState, setChildrenState] = useState([]);
  const [mtgroup, setMTGroup] = useState([]);
  const [users, setUsers] = useState([]);
  const [parentID, setParentId] = useState(null);
  const [formId, setFormId] = useState(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const [isR1Checked, setIsR1Checked] = useState(false);
  const [isMTChecked, setIsMTChecked] = useState(false);
  const [discipline, setDiscipline] = useState(null);
  const [hostSystemOwner, setHostSystemOwner] = useState(null);

  useEffect(() => {
    setState(permit?.submission || {});
    setChildrenState(permit?.children || []);
    setParentId(permit?.parentPermit?._id?._id);
    setFormId(permit?.form?._id);
  }, [data]);

  useEffect(() => {
    if (data) {
      getUsers();
      getGroups();
    }
  }, [data]);

  function onChangeR1Checkbox(e) {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      setIsR1Checked(true);
    } else {
      setIsR1Checked(false);
    }
  }

  function onChangeMTCheckbox(e) {
    console.log(`checked = ${e.target.checked}`);

    if (e.target.checked) {
      setIsMTChecked(true);
    } else {
      setIsMTChecked(false);
    }
  }

  const onSearchUser = React.useMemo(() => {
    const loadOptions = (value) => {
      getUsers({
        variables: {
          limit: 100,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);
  const onSearchGroup = React.useMemo(() => {
    const loadOptions = (value) => {
      getGroups({
        variables: {
          limit: 10,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);
  const onChange = (values) => {
    setState({ ...state, ...values });
  };
  const onPermitChange = (values) => {
    setState({ ...state, ...values });
  };

  const onUpdateStatus = async (values) => {
    try {
      const { data } = await UpdateChildPermitStatus({
        variables: {
          id: match.params.id,
          status: values.status,
          reason: values.reason,
        },
      });
      if (data.UpdateChildPermitStatus.success) {
        successNotify(data.UpdateChildPermitStatus.message);
        refetch();
      } else {
        warnNotify(data.UpdateChildPermitStatus.message);
      }
    } catch (error) {}
  };
  const onUpdatePermit = async (values) => {
    try {
      const { data } = await UpdateChildPermit({
        variables: {
          id: match.params.id,
          status: values.status,
          submission: JSON.stringify(state),
          parentID: parentID,
          formId: formId,
        },
      });
      if (data.UpdateChildPermit.success) {
        setDisabledButton(true);
        successNotify(data.UpdateChildPermit.message);
        refetch();
        history.push("/childpermits");
      } else {
        warnNotify(data.UpdateChildPermit.message);
      }
    } catch (error) {}
  };
  const onUpdateApprovedPermit = async (values) => {
    try {
      const { data } = await UpdateApprovedPermit({
        variables: {
          id: match.params.id,
          status: values.status,
          submission: JSON.stringify(state),
          childrenState: JSON.stringify(childrenState),
        },
      });
      if (data.UpdatesOnApprovedPermit.success) {
        successNotify(data.UpdatesOnApprovedPermit.message);
        refetch();
      } else {
        warnNotify(data.UpdatesOnApprovedPermit.message);
      }
    } catch (error) {}
  };
  const handleApprove = (e) => {
    e.preventDefault();
    let mtGrpCount = 0;

    let _userId = authUser._id;

    let _approvedBy = permit?.approvedBy;
    let _selectedUser = permit?.selectedUser;
    let _selectedMTGroupUsers = permit?.selectedMTGroupUsers;
    let _selectedMtGroup = permit?.selectedMTGroup;

    let cleanSelectedUsers = getIndividualReviewersOnly(_selectedUser, _selectedMTGroupUsers);

    if (Array.isArray(_selectedMtGroup)) {
      mtGrpCount = _selectedMtGroup.length;
    }

    // let cleanSelectedUsers = _.compact(__selected);

    if (_selectedMtGroup.length === 0) {
      warnNotify("Please select MtGroup!");
    } else if (_selectedUser.length === 0) {
      warnNotify("Please select Individual Reviewer!");
    } else {
      if (authUser.role === UserRole.ContractorPM) {
        onUpdatePermit({ status: PermitStatus.PendingApproval });
      } else if (authUser.role === UserRole.MicronSupervisor) {
        onUpdatePermit({ status: PermitStatus.PendingApproval });
      } else {
        /** Insert MTGroup Review/Approve Logic Here */
        let approveCountNeeded = mtGrpCount + cleanSelectedUsers.length;

        console.log("approveCountNeeded: " + approveCountNeeded);
        console.log("number of approves: " + _approvedBy.length);

        if (cleanSelectedUsers.some((user) => user._id === _userId)) {
          console.log("Approving by Individual Reviewer");

          let indApproveCount = 0;
          let mtUsrApproveCount = 0;

          /** Count the number of individual approves */
          _approvedBy.map((usr) => {
            if (cleanSelectedUsers.some((o) => o._id === usr._id)) {
              indApproveCount++;
            }
          });

          /** Count the number of MTGroup approves */
          for (var i = 0; i < mtGrpCount; i++) {
            _selectedMtGroup[i].members.map((usrs) => {
              if (_approvedBy.some((o) => o._id === usrs._id)) {
                mtUsrApproveCount++;
              }
            });
          }

          console.log(
            "individual Approves: " +
              indApproveCount +
              " | mtGroup Approves: " +
              mtUsrApproveCount +
              " | approve needed: " +
              approveCountNeeded
          );

          let totalApproves = indApproveCount + mtUsrApproveCount;
          let isPermitApproved = totalApproves + 1;

          console.log("totalApproves: " + totalApproves);

          console.log("approveCountNeeded: " + approveCountNeeded);
          console.log("Permit Approved? " + isPermitApproved);

          if (isPermitApproved < approveCountNeeded) {
            console.log("Approved by Individual Reviewer, no change in permit status");
            onUpdatePermit({ status: PermitStatus.PendingApproval });
          }

          if (isPermitApproved >= approveCountNeeded) {
            console.log("Approved by Individual Reviewer, permit status changed");
            onUpdatePermit({ status: PermitStatus.Approved });
          }
        } else {
          if (_selectedMTGroupUsers.some((user) => user._id === _userId)) {
            console.log("Approving by MTGroup Member");
            let indApproveCount = 0;
            let mtUsrApproveCount = 0;

            /** Count the number of individual approves */
            _approvedBy.map((usr) => {
              if (cleanSelectedUsers.some((o) => o._id === usr._id)) {
                indApproveCount++;
              }
            });

            /** Count the number of MTGroup approves */
            for (var _i = 0; _i < mtGrpCount; _i++) {
              _selectedMtGroup[_i].members.map((usrs) => {
                if (_approvedBy.some((o) => o._id === usrs._id)) {
                  mtUsrApproveCount++;
                }
              });
            }

            console.log(
              "individual Approves: " +
                indApproveCount +
                " | mtGroup Approves: " +
                mtUsrApproveCount +
                " | approve needed: " +
                approveCountNeeded
            );

            let totalApproves = indApproveCount + mtUsrApproveCount;
            let isPermitApproved = totalApproves + 1;

            console.log("totalApproves: " + totalApproves);

            console.log("approveCountNeeded: " + approveCountNeeded);
            console.log("Permit Approved? " + isPermitApproved);

            if (isPermitApproved < approveCountNeeded) {
              console.log("Approved by MT Group Reviewer, no change in permit status");
              onUpdatePermit({ status: PermitStatus.PendingApproval });
            }

            if (isPermitApproved >= approveCountNeeded) {
              console.log("Approved by MT Group Reviewer, permit status changed");
              onUpdatePermit({ status: PermitStatus.Approved });
            }
          }
        }
      }
    }
  };
  const handleClosed = (e) => {
    e.preventDefault();
    onUpdatePermit({ status: PermitStatus.Completed });
  };
  const handleCancelled = (e) => {
    e.preventDefault();
    onUpdatePermit({ status: PermitStatus.Cancelled });
  };
  const handlePendingClosure = (e) => {
    e.preventDefault();
    var proceedToClose = window.confirm(
      `CAUTION, YOU ARE CLOSING THE PERMIT NOW. NO FURTHER RECTIFICATION IS ALLOWED AFTER PERMIT IS CLOSED. Continue?`
    );

    if (proceedToClose) {
      onUpdatePermit({ status: PermitStatus.PendingClosure });
    }
  };
  const handlePendingCancel = (e) => {
    e.preventDefault();
    var proceedToCancel = window.confirm(
      `CAUTION, YOU ARE CANCELING THE PERMIT NOW. NO FURTHER RECTIFICATION IS ALLOWED AFTER PERMIT IS CANCELLED. Continue?`
    );

    if (proceedToCancel) {
      onUpdatePermit({ status: PermitStatus.PendingCancel });
    }
  };
  // const handleUpdate = (e) => {
  //   e.preventDefault();
  //   onUpdatePermit({ status: PermitStatus.Approved });
  // };
  const handleUpdateApprovedPermit = (e) => {
    e.preventDefault();
    onUpdateApprovedPermit({ status: PermitStatus.Approved });
  };
  const handleResubmit = (e) => {
    e.preventDefault();
    onUpdatePermit({ status: PermitStatus.Pending });
  };
  const handleReject = (e) => {
    e.preventDefault();
    onUpdateStatus({
      status: PermitStatus.Rejected,
      reason: reason,
    });

    setVisible(false);
  };
  const onChangeHandler = (values, child) => {
    console.log("\n");
    const selectedChild = childrenState?.find((d) => d._id === child._id);

    if (selectedChild) {
      console.log("selectedChild is true.");
      Object.assign(selectedChild.submission, values);
      setChildrenState([...childrenState, ...selectedChild]);
    } else {
      if (childrenState?.length > 0) {
        console.log("childrenState?.length > 0");
        childrenState.push({
          form: child?._id,
          submission: values,
        });
      } else {
        console.log("!!!!childrenState?.length > 0");
        setChildrenState([
          {
            form: child?._id,
            submission: values,
          },
        ]);
      }
    }
  };
  const onUpdateReviewers = async () => {
    console.log("users: " + JSON.stringify(users, null, 2));
    console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));

    try {
      let newSelectedUsers = [];
      let newMTGroups = [];
      let reviewersOkToSubmit = false;
      let mtGrpsOkToSubmit = false;

      if (isR1Checked === false && isMTChecked === false) {
        warnNotify("Please tick any checkbox and select reviewer or MTGroup first.");
      } else {
        if ((users === null || users === []) && (mtgroup === null || mtgroup === [])) {
          warnNotify("You cannot submit empty fields when updating reviewers.");
          reviewersOkToSubmit = false;
          mtGrpsOkToSubmit = false;
        } else {
          if (isR1Checked) {
            if (users) {
              let hasSameReviewer = permit?.selectedUser.map((usr) => {
                let res = users && users.some((usrId) => usrId.toString() === usr._id.toString());
                return res;
              });

              if (hasSameReviewer.includes(true)) {
                warnNotify("You cannot add the same Individual Reviewer or someone from the selected MTGroup.");
                reviewersOkToSubmit = false;
              } else {
                newSelectedUsers = users;
                reviewersOkToSubmit = true;
              }
            } else {
              warnNotify("You cannot submit empty fields when updating reviewers.");
              reviewersOkToSubmit = false;
            }
          } else {
            console.log("No Change in Individual Reviewer");
            reviewersOkToSubmit = true;
          }

          if (isMTChecked) {
            if (mtgroup) {
              if (permit?.selectedMTGroup) {
                let hasSameMTGroup =
                  permit?.selectedMTGroup &&
                  permit?.selectedMTGroup.map((grp) => {
                    let res = mtgroup && mtgroup.some((sGrp) => sGrp.toString() === grp._id.toString());
                    return res;
                  });
                if (hasSameMTGroup.includes(true)) {
                  warnNotify("You cannot add the same MTGroup.");
                  mtGrpsOkToSubmit = false;
                } else {
                  newMTGroups = mtgroup;
                  mtGrpsOkToSubmit = true;
                }
              } else {
                newMTGroups = mtgroup;
                mtGrpsOkToSubmit = true;
              }
            } else {
              warnNotify("You cannot submit empty fields when updating reviewers.");
              mtGrpsOkToSubmit = false;
            }
          } else {
            console.log("No Change in MT Groups");
            mtGrpsOkToSubmit = true;
          }
        }

        // mutation here, add new selected users to selectedUsers in backend
        // and new MTGroup Members to selectedUsers and selectedMTGroupUsers
        if (reviewersOkToSubmit && mtGrpsOkToSubmit) {
          try {
            console.log("\n===================");
            console.log("permit id: " + permit._id);
            console.log("\nnewSelectedUsers: " + JSON.stringify(newSelectedUsers, null, 2));
            console.log("\nnewMTGroups: " + JSON.stringify(newMTGroups, null, 2));
            console.log("\n===================");

            const { data } = await UpdateChildPermitReviewers({
              variables: {
                id: permit._id,
                newIndividualReviewers: newSelectedUsers,
                newMTGroups: newMTGroups,
              },
            });

            if (data.UpdateChildPermitReviewers.success) {
              successNotify(data.UpdateChildPermitReviewers.message);
              refetch();
            } else {
              warnNotify(data.UpdateChildPermitReviewers.message);
            }
          } catch (updErr) {
            console.log(updErr);
          }
        } else {
          console.log("Not submitting updates to backend.");
        }
      }
    } catch (err) {
      console.log("Something went wrong updating reviewers");
      console.log(err);
    }
  };

  const onRemoveIndividualReviewer = async (userId) => {
    try {
      const { data } = await UpdateRemoveChildIndividualReviewer({
        variables: {
          id: permit?._id,
          userId: userId,
        },
      });

      if (data.UpdateRemoveChildIndividualReviewer.success) {
        successNotify(data.UpdateRemoveChildIndividualReviewer.message);
        refetch();
      } else {
        warnNotify(data.UpdateRemoveChildIndividualReviewer.message);
      }
    } catch (error) {}
  };

  const handleDelete = (userData) => () => {
    var removeIndividualReviewer = window.confirm(
      `You are removing ${userData?.email} in individual reviewer's list. Continue?`
    );

    if (removeIndividualReviewer) {
      console.log("Removed " + userData?._id + " as individual reviewer on permit " + permit?._id);
      onRemoveIndividualReviewer(userData._id);
    }
  };

  const onRemoveMTGroupReviewer = async (mtgroupId) => {
    let neededApproveCount = getApproveCountNeeded(permit);
    let numberOfApproves = getNumberOfApproves(permit);
    let approvalRemaining = neededApproveCount - numberOfApproves;

    if (approvalRemaining <= 1) {
      const r = window.confirm(
        "If you remove this last reviewer, the permit status will change to Approved. Continue?"
      );
      if (r) {
        try {
          const { data } = await UpdateRemoveChildMTGroupReviewer({
            variables: {
              id: permit?._id,
              mtGroupId: mtgroupId,
            },
          });

          if (data.UpdateRemoveChildMTGroupReviewer.success) {
            successNotify(data.UpdateRemoveChildMTGroupReviewer.message);
            updatePermitStatusReviewerRemoved();
          } else {
            warnNotify(data.UpdateRemoveChildMTGroupReviewer.message);
          }
        } catch (err) {}
      }
    } else {
      try {
        const { data } = await UpdateRemoveChildMTGroupReviewer({
          variables: {
            id: permit?._id,
            mtGroupId: mtgroupId,
          },
        });

        if (data.UpdateRemoveChildMTGroupReviewer.success) {
          successNotify(data.UpdateRemoveChildMTGroupReviewer.message);
          refetch();
        } else {
          warnNotify(data.UpdateRemoveChildMTGroupReviewer.message);
        }
      } catch (error) {}
    }
  };

  const updatePermitStatusReviewerRemoved = async () => {
    const { data: approvePermitData } = await ApprovePermitWhenLastReviewerIsRemoved({
      variables: {
        id: permit?._id,
        status: PermitStatus.Approved,
      },
    });

    if (approvePermitData.ApprovePermitWhenLastReviewerIsRemoved.success) {
      successNotify(approvePermitData.ApprovePermitWhenLastReviewerIsRemoved.message);
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } else {
      warnNotify(approvePermitData.ApprovePermitWhenLastReviewerIsRemoved.message);
    }
  };

  const handleDeleteMTGroup = (mtGroupData) => () => {
    var removeMTGroup = window.confirm(`You are removing ${mtGroupData?.name} in reviewer's list. Continue?`);

    if (removeMTGroup) {
      console.log("Removed " + mtGroupData?._id + " as MTGroup reviewer on permit " + permit?._id);
      onRemoveMTGroupReviewer(mtGroupData._id);
    }
  };

  let permit = {};
  if (data?.GetChildPermit?.permit) {
    permit = JSON.parse(data?.GetChildPermit?.permit || {});
  }

  let selectHostOwnerValue = permit?.hostSystemOwner?.email;
  let selectDisciplineValue = permit?.discipline?.code;

  const selectedHostOwnerProps = {
    style: { width: "100%" },
    value: selectHostOwnerValue,
  };

  const selectedDisciplineProps = {
    style: { width: "100%" },
    value: selectDisciplineValue,
  };

  const approvedBy = permit?.approvedBy?.find((user) => user._id === authUser._id);
  // const contractorPM = permit?.approvedBy?.length > 0 && permit?.approvedBy[0];
  const allApproves = permit?.approvedBy?.length ? permit?.approvedBy : null;
  const status = permit?.status;

  let showReviewActions = false;
  let hideApproveButton = false;
  let updateReviewersComponents = true;
  let showCompleteButton = false;
  let showCancelButton = false;

  let showSaveButtonOnApprovedPermit = false;

  let isUserPermitCloser = IsPermitCloser(authUser._id);
  let isSameSite = IsUserMTGroupSiteSameWithPermitSite(authUser._id, permit?.site);

  if (status === PermitStatus.PendingClosure) {
    if (authUser.role === UserRole.MTGroup) {
      if (isUserPermitCloser) {
        if (isSameSite) {
          showCompleteButton = true;
        }
      }
    }
  }

  if (status === PermitStatus.PendingCancel) {
    if (authUser.role === UserRole.MTGroup) {
      if (isUserPermitCloser) {
        if (isSameSite) {
          showCancelButton = true;
        }
      }
    }
  }

  if (status === PermitStatus.Pending || status === PermitStatus.PendingApproval || status === PermitStatus.Rejected) {
    updateReviewersComponents = false;
  }

  if (status === PermitStatus.Pending && authUser.role === UserRole.ContractorPM) {
    console.log("Permit status is " + status + " | user role is " + authUser.role);
    showReviewActions = true;
  }

  if (authUser.role === UserRole.ContractorPM && status === PermitStatus.PendingApproval) {
    showReviewActions = false;
  }

  if (authUser.role === UserRole.MTGroup && status === PermitStatus.PendingApproval) {
    showReviewActions = true;
  }

  if (
    authUser.role === UserRole.MTGroup &&
    status === PermitStatus.Approved &&
    authUser._id !== permit?.submittedBy?._id
  ) {
    showSaveButtonOnApprovedPermit = true;
  }

  if (status === PermitStatus.Rejected) showReviewActions = false;
  if (approvedBy) showReviewActions = false;
  // if (contractorPM.role === UserRole.ContractorPM) showReviewActions = false;

  if (authUser._id === permit?.submittedBy?._id && authUser.role === UserRole.MTGroup) showReviewActions = false;

  if (data?.GetChildPermit?.success === false) {
    return <div className="card">Not found</div>;
  }

  let _selectedMtGroup = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup : null;

  if (_selectedMtGroup === undefined || _selectedMtGroup === null || _selectedMtGroup === []) {
    console.log("No MTGroup Selected");
    let hideButton = true;

    console.log("======== " + status + " ========= " + authUser.role);

    if (status === PermitStatus.Pending && authUser.role === UserRole.ContractorPM) {
      hideButton = false;
    }

    if (status === PermitStatus.PendingApproval && authUser.role === UserRole.MTGroup) {
      hideButton = false;
    }

    hideApproveButton = hideButton;
  } else {
    let showButton = true;

    for (var i = 0; i < _selectedMtGroup.length; i++) {
      if (_selectedMtGroup[i].members.some((p) => p._id === authUser._id)) {
        console.log(
          "User is a member of a selected MTGroup: " +
            _selectedMtGroup[i].name +
            "? " +
            _selectedMtGroup[i].members.some((p) => p._id === authUser._id)
        );

        const hasMemberApproved = permit.approvedBy.map((pApproved) => {
          return _selectedMtGroup[i].members.some((mmbr) => mmbr._id == pApproved._id);
          // return res;
        });

        if (hasMemberApproved.includes(true)) {
          showButton = true;
          console.log(
            "One members on same MTGroup Approved already? " +
              hasMemberApproved.includes(true) +
              "\nHide approve button? " +
              showButton
          );
          break;
        } else {
          showButton = false;
          console.log(
            "One members on same MTGroup Approved already? " +
              hasMemberApproved.includes(true) +
              "\nHide approve button? " +
              showButton
          );
          break;
        }
      } else {
        console.log("User is not a member of any selected MTGroup");
        showButton = false;
      }
    }

    hideApproveButton = showButton;
  }

  //MODAL FOR COMMENT REJECT

  const showModal = (record) => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  let selectDefaultValue = permit?.selectedUser?.map((user) => {
    if (!permit?.selectedMTGroupUsers?.find((o2) => user.email === o2.email)) {
      return user;
    }
  });

  console.log("hideApproveButton for PM? " + hideApproveButton);

  console.log("=====================================================");
  console.log("getMTGroupApproveCount: " + getMTGroupApproveCount(permit));
  console.log("getIndividualReviewerApproveCount: " + getIndividualReviewerApproveCount(permit));
  console.log("getApproveCountNeeded: " + getApproveCountNeeded(permit));
  console.log("getNumberOfApproves: " + getNumberOfApproves(permit));

  let indRevs = getIndividualReviewersOnly(permit?.selectedUser, permit?.selectedMTGroupUsers);
  let indRevsCount = indRevs?.length ? indRevs?.length : 0;
  let mtRevsCount = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup?.length : 0;
  let totalRevs = indRevsCount + mtRevsCount;

  let showChildPermitSection = permit?.children?.length ? true : false;

  console.log("Number of Reviewers: " + totalRevs);

  console.log("=====================================================");

  return (
    <Spin spinning={loading}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-3" />

                <div className="form-group col-md-3">
                  <Title level={5}>Selected Individual Reviewers</Title>
                  {selectDefaultValue?.map((user) => {
                    if (user) {
                      let userApproved = userInApprovedBy(user?._id, allApproves);
                      let chipColor = "grey";

                      if (userApproved) {
                        chipColor = "primary";
                      }

                      return (
                        <Chip
                          icon={<UserOutlined />}
                          label={user?.email}
                          color={chipColor}
                          onDelete={userApproved ? undefined : totalRevs <= 1 ? undefined : handleDelete(user)}
                          style={{ margin: "0.5%" }}
                        />
                      );
                    }
                  })}
                </div>

                <div className="form-group col-md-3">
                  <Title level={5}>Selected MTGroups</Title>
                  {permit?.selectedMTGroup?.map((mtgroup) => {
                    let memberApproved = false;
                    let members = mtgroup?.members?.length ? mtgroup?.members : null;
                    let chipColor = "grey";

                    let groupHasNoMemberShowToolTip = true;

                    // console.log("members: " + JSON.stringify(members, null, 2));

                    if (members !== null) {
                      for (let i = 0; i < members.length; i++) {
                        let approved = userInApprovedBy(members[i]._id, allApproves);

                        if (approved) {
                          memberApproved = true;
                          chipColor = "primary";
                          break;
                        }
                      }
                    } else {
                      chipColor = "secondary";
                      groupHasNoMemberShowToolTip = false;
                    }

                    return (
                      <Tooltip
                        title={
                          <span style={{ fontSize: "14px" }}>
                            This MTGroup has no members. Please remove from permit and ask admin to add members before
                            adding the MTGroup again as permit reviewer.
                          </span>
                        }
                        placement="top-start"
                        enterDelay={200}
                        leaveDelay={500}
                        disableHoverListener={groupHasNoMemberShowToolTip}
                        style={{ backgroundColor: "red", color: "white" }}
                      >
                        <Chip
                          icon={<TeamOutlined />}
                          label={mtgroup?.name}
                          color={chipColor}
                          onDelete={
                            memberApproved ? undefined : totalRevs <= 1 ? undefined : handleDeleteMTGroup(mtgroup)
                          }
                          style={{ margin: "0.5%" }}
                        />
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-3" />
                <div className="form-group col-md-3" style={{ alignSelf: "flex-end" }}>
                  <Checkbox onChange={onChangeR1Checkbox} disabled={updateReviewersComponents}>
                    {" "}
                    Include to update{" "}
                  </Checkbox>
                  <Select
                    // disabled={isR1Checked}
                    style={{ width: "100%" }}
                    onChange={(value) => setUsers(value)}
                    placeholder="Select Reviewer"
                    showSearch={true}
                    onSearch={onSearchUser}
                    multiple
                    filterOption={false}
                    notFoundContent={fetchingUser ? <Spin size="small" /> : null}
                    mode="multiple"
                    disabled={updateReviewersComponents || !isR1Checked}
                    onSelect={(value) => {
                      var hasDuplicateReviewer;
                      if (mtgroup !== null) {
                        // let result_1 = permit?.selectedMTGroup.filter(o1 => mtgroup?.some(o2 => o1._id === o2));
                        let combinedArray = permit?.selectedMTGroup.map((res) => res.members);
                        let merged = [].concat.apply([], combinedArray);
                        hasDuplicateReviewer = merged.filter(
                          (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                        );
                      }

                      if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                        warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                        setUsers([]);
                      }
                    }}
                    value={users}
                  >
                    {UserList?.GetAllUsers?.results
                      ?.sort((a, b) => a.email.localeCompare(b.email))
                      .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                      .map((user) => (
                        <Select.Option key={user._id} value={user._id}>
                          <strong>{user.fullname}</strong>
                          <br />
                          <span>{user.email}</span>
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group col-md-3" style={{ alignSelf: "flex-end" }}>
                  <Checkbox onChange={onChangeMTCheckbox} disabled={updateReviewersComponents}>
                    {" "}
                    Include to update{" "}
                  </Checkbox>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) => setMTGroup(value)}
                    placeholder="Select MT Group"
                    showSearch={true}
                    onSearch={onSearchGroup}
                    multiple
                    filterOption={false}
                    notFoundContent={fetchingGroup ? <Spin size="small" /> : null}
                    mode="multiple"
                    disabled={updateReviewersComponents || !isMTChecked}
                    onSelect={(value) => {
                      var hasDuplicate;
                      var hasDuplicateReviewer;
                      let result = GroupList?.GetUserGroups?.results?.filter(
                        (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                      );
                      let getMembersToCheck = result.map((res) => res.members);
                      console.log(getMembersToCheck);

                      if (mtgroup !== null) {
                        let combinedArray = permit?.selectedMTGroup?.map((res) => res.members);
                        let merged = [].concat.apply([], combinedArray);
                        hasDuplicate = merged.filter((o1) => getMembersToCheck[0].some((o2) => o1._id === o2._id));
                        hasDuplicateReviewer = users.filter((o1) => getMembersToCheck[0].some((o2) => o1 === o2._id));
                      }

                      if (users !== null) {
                        hasDuplicateReviewer = users.filter((o1) => getMembersToCheck[0].some((o2) => o1 === o2._id));
                      }

                      if (hasDuplicate !== undefined && hasDuplicate.length !== 0) {
                        warnNotify("One of the User has same id in other MTgroup.");
                        setMTGroup([]);
                      } else if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                        warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                        setMTGroup([]);
                      }
                    }}
                    value={mtgroup}
                  >
                    {GroupList?.GetUserGroups?.results
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((group) => (
                        <Select.Option key={group._id} value={group._id}>
                          {group.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group col-md-3" style={{ alignSelf: "flex-end" }}>
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      onUpdateReviewers();
                    }}
                    disabled={updateReviewersComponents}
                  >
                    Update Reviewers
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider />
      <br />

      <div className="row">
        <div className="col-12">
          <div className="create-permit-details">
            <div className="form-row">
              {permit && (
                <div className="form-group col-md-3">
                  Host/System Owner <br />
                  <Select {...selectedHostOwnerProps} disabled /> <br />
                </div>
              )}

              {permit && (
                <div className="form-group col-md-3">
                  Discipline <br />
                  <Select {...selectedDisciplineProps} disabled />
                </div>
              )}
              <div className="form-group col-md-3" />
              <div className="form-group col-md-3" />
            </div>
          </div>
        </div>
      </div>

      <div className="heading">
        <Link to="/childpermits" className="btn-link">
          <i className="far fa-arrow-left"></i> Back to view all child permit
        </Link>
      </div>

      <PermitUserInfo permit={permit} showAction={false} />

      <form>
        {permit?.form?.sections?.map((section, key) => (
          <PermitSection
            permitStatus={permit?.status}  
            section={section}
            key={key}
            onChange={onPermitChange}
            state={permit?.submission}
            formState={state}
            isParent={true}
            siteID={permit?.site}
            formRef={formRef}
            permitIndivudualReviewers={permit?.selectedUser}
            permitMtGroupReviewers={permit?.selectedMTGroupUsers}
          />
        ))}

        {/* {childtemplate?.length > 0 && <ChildPermit templates={childtemplate} onChange={onChange} state={state} />} */}

        {showChildPermitSection ? (
          <Fragment>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
              <br />
              <h2>Child permit(s)</h2>
            </div>
            {permit?.children?.map((child) => (
              <React.Fragment>
                <Divider />
                <div>
                  <h3>{child?.form?.title}</h3>
                </div>

                {child?.form?.sections?.map((_section, _key) => (
                  <div>
                    <PermitSection
                      permitStatus={permit?.status}
                      section={_section}
                      templateKey={_key}
                      onChange={(values) => onChangeHandler(values, child)}
                      state={child?.submission}
                      isParent={false}
                      permitIndivudualReviewers={permit?.selectedUser}
                      permitMtGroupReviewers={permit?.selectedMTGroupUsers}
                    />
                  </div>
                ))}
                <br />
              </React.Fragment>
            ))}
          </Fragment>
        ) : (
          <Fragment />
        )}

        {showReviewActions ? (
          <div className="account-details-btn ">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/childpermits/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <div hidden={hideApproveButton}>
              <CustomButton
                type="submit"
                loading={updateLoading}
                className="btn btn-primary"
                style={{ maxWidth: 300 }}
                onClick={handleApprove}
                disabled={disabledButton}
              >
                Approve and Continue
              </CustomButton>
            </div>
            <CustomButton
              onClick={(event) => {
                event.preventDefault();
                showModal();
              }}
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
            >
              Reject
            </CustomButton>
          </div>
        ) : (
          "  "
        )}

        {authUser.role === UserRole.MTGroup && status === PermitStatus.Pending ? (
          <div>
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/childpermit/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <CustomButton
              onClick={(event) => {
                event.preventDefault();
                showModal();
              }}
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
            >
              Reject
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {showCompleteButton ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/childpermit/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              style={{ maxWidth: 300 }}
              onClick={handleClosed}
            >
              Close
            </CustomButton>
          </div>
        ) : showCancelButton ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/childpermit/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              style={{ maxWidth: 300 }}
              onClick={handleCancelled}
            >
              Cancel
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {(authUser.role === UserRole.ContractorRequestor ||
          authUser.role === UserRole.MTGroup ||
          authUser.role === UserRole.User) &&
        authUser._id === permit?.submittedBy?._id &&
        status === PermitStatus.Approved ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/childpermits/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateApprovedPermitLoading}
              className="btn btn-primary"
              style={{ maxWidth: 300 }}
              onClick={handleUpdateApprovedPermit}
            >
              Save
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateLoading}
              className="btn btn-primary ml-3"
              style={{ maxWidth: 300 }}
              onClick={handlePendingClosure}
            >
              Pending Closure
            </CustomButton>

            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              onClick={handlePendingCancel}
              style={{ maxWidth: 300 }}
            >
              Pending Cancel
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {(authUser.role === UserRole.ContractorRequestor ||
          authUser.role === UserRole.MTGroup ||
          authUser.role === UserRole.User) &&
        status === PermitStatus.Rejected ? (
          <div>
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/childpermits/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateLoading}
              className="btn btn-primary"
              style={{ maxWidth: 300 }}
              onClick={handleResubmit}
            >
              Resubmit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateStatusLoading}
              className="btn btn-danger ml-3"
              onClick={handleCancelled}
              style={{ maxWidth: 300 }}
            >
              Cancelled
            </CustomButton>
          </div>
        ) : (
          ""
        )}

        {showSaveButtonOnApprovedPermit ? (
          <div className="account-details-btn">
            <CustomButton
              className="btn btn-primary"
              style={{ marginRight: "1%" }}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/childpermits/${permit?._id}`);
              }}
            >
              View Permit
            </CustomButton>
            <CustomButton
              type="submit"
              loading={updateApprovedPermitLoading}
              className="btn btn-primary"
              style={{ maxWidth: 300 }}
              onClick={handleUpdateApprovedPermit}
              hidden={permit?.status === PermitStatus.Approved}
            >
              Save Permit
            </CustomButton>
          </div>
        ) : (
          ""
        )}
      </form>

      <Modal title={"Reject Permit"} visible={visible} onOk={handleReject} onCancel={handleCancel}>
        <textarea
          rows={5}
          className="form-control"
          placeholder="Reason"
          name="rejectpermit"
          onChange={(e) => onChange(setRejectReason(e.target.value))}
        ></textarea>
      </Modal>
    </Spin>
  );
};
