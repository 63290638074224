import React, { useState, useEffect } from "react";
import { DynamicList } from "../components/Shared/DynamicList";
import { EllipsisOutlined, UploadOutlined } from "@ant-design/icons";
import { message, Pagination, Select, Dropdown, Menu, Button, Popconfirm, Upload, Modal, Spin } from "antd";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { GET_SITE_LIST, GET_MAP_LIST, DELETE_MAP, FILE_UPLOAD, DELETE_FILE, UPDATE_MAP, GENERATE_PRESIGNED_URL } from "../graphql/modules";
import { successNotify, warnNotify, formatImage, TABLE_LIST_LIMIT } from "../util";
import { Link } from "react-router-dom";
import "../styles/components/allmap.scss";

export const AllMaps = ({ match }) => {
  const [getPresignedUrl, {data: PreSignedUrlData }] = useLazyQuery(GENERATE_PRESIGNED_URL);

  const [FileUpload, { loading: uploadLoading }] = useMutation(FILE_UPLOAD);
  const [DeleteFile, { loading: deleteLoading }] = useMutation(DELETE_FILE);
  const [fileList, setFileList] = useState([]);
  const [item, setItem] = useState("");
  const [id, setID] = useState("");
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [isSpinHidden, setIsSpinHidden] = useState(true);
  const [value, setValue] = useState("");

  const [selectedMapUrl, setSelectedMapUrl] = useState("");

  const [state, setState] = useState({
    name: "",
    site: "",
    mapImage: "",
  });

  const [mapModal, setMapModal] = useState(false);
  const [selectedMap, setSelectedMap] = useState(false);

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data, loading, error, refetch } = useQuery(GET_MAP_LIST, {
    variables: {
      limit,
      offset: page - 1,
      filterOption: value,
    },
    fetchPolicy: "cache-and-network",
  });

  const [UpdateMapMutation, { loading: updateLoading }] = useMutation(UPDATE_MAP);
  const [DeleteMap] = useMutation(DELETE_MAP);

  const handleDelete = async (id) => {
    await DeleteMap({ variables: { id } });
    refetch();
  };

  const onChangePagination = (p, size) => {
    setPage(p);
    setLimit(size);
  };

  const handleOk = async () => {
    try {
      const {
        data: { UpdateMap },
      } = await UpdateMapMutation({
        variables: {
          id: id,
          data: state,
        },
      });
      if (UpdateMap.success) {
        refetch();
        successNotify(UpdateMap.message);
        setVisible(false);
      } else {
        warnNotify(UpdateMap.message);
      }
    } catch (error) {
      warnNotify(error.message);
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const props = {
    name: "file",
    multiple: false,
    customRequest: async ({ file, onSuccess }) => {
      try {
        const {
          data: { SingleUpload },
        } = await FileUpload({
          variables: {
            file,
          },
        });
        file.url = SingleUpload?.url;
        file.filename = SingleUpload?.filename;
        onSuccess("done");
      } catch (error) {
        setFileList([]);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
        setIsSpinHidden(true);
      }
    },

    onRemove: async (file) => {
      try {
        await DeleteFile({
          variables: {
            filename: file.filename,
          },
        });

        const files = fileList.filter((f) => f.uid !== file.uid);
        setFileList(files);
      } catch (error) {
        console.log("onRemove");
        console.log(error);
      }
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
    },
    fileList,
    // multiple: false,
    listType: "picture",
    onChange(info, list) {
      if (info.file.status === "uploading") {
        setIsSpinHidden(false);
      }

      if (info.file.status === "done") {
        message.success(`File uploaded successfully`);
        setState({ ...state, mapImage: info.file.filename });
        setIsSpinHidden(true);
      } else if (info.file.status === "error") {
        setIsSpinHidden(true);
        setFileList([]);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
      }
    },

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const showModal = (record) => {
    setID(record._id);
    setState({
      name: record.name,
      site: record.site,
      mapImage: record.mapImage,
    });
    setVisible(true);
  };

  const total = data?.GetMap?.count;
  const results = data?.GetMap?.results;

  function onChangeSearch(siteid, p) {
    setValue(siteid);
    setPage(1);
  }

  // {url, mapName}
  const handleEnlargeImage = (imgUrlObj) => {
    setSelectedMap(imgUrlObj);
    // setMapModal(true);
  };

  const fetchPresignedUrl = async (filename) => {
    // console.log("fetchPresignedUrl filename: " + filename);
    try {
      getPresignedUrl({ variables: { 
        filename: filename, 
        // contentType: "image/png" 
      } 
      });
    } catch (error) {
      console.error("Error fetching pre-signed URL:", error);
      return null;
    }
  };

  const handleGetPresignedUrlData = async (filename) => {
    // console.log("handleGetPresignedUrlData: " + filename);
    fetchPresignedUrl(filename);
  };

  const handleUploadedFileOnclick = (filename) => {
    // console.log("handleUploadedFileOnclick: " + filename);
    handleGetPresignedUrlData(filename);
  }

  const columns = [
    {
      title: "No",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
    },
    {
      title: "Picture thumbnail 111",
      dataIndex: "mapImage",
      render: (record) => {
        return (
          <Button
            onClick={async (e) => {
              e.preventDefault();
              handleUploadedFileOnclick(record);

              let imgUrlObj ={
                url: selectedMapUrl,
                mapName: record
              }
              console.log("imgUrlObj: " + JSON.stringify(imgUrlObj, null, 2));
              handleEnlargeImage(imgUrlObj);
            }}
          >
            Show Map Image
          </Button>
        )
        
      }
      ,
      key: "mapImage",
    },
    {
      title: "Sites",
      render: (record) => {
        const datamap = SiteList?.GetSites?.results?.filter((_site) => {
          if (_site._id === record.site) {
            return _site.code;
          }
        });

        return <p>{datamap?.map((res) => res.code)}</p>;
      },
      key: "site",
    },
    {
      title: "Option",
      dataIndex: "option",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="0">
              <Link style={{ marginRight: "15px" }} to={`${match.path}/edit-map/${record._id}`}>
                Change Name
              </Link>
            </Menu.Item>
            <Menu.Item key="1">
              <Button
                type="text"
                style={{ marginLeft: "-14px" }}
                onClick={(event) => {
                  event.preventDefault();
                  showModal(record);
                }}
              >
                Re-Upload
              </Button>
            </Menu.Item>
            <Menu.Item key="2">
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
                Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
      key: "option",
    },
  ];

  useEffect(() => {
    // console.log("useEffect PreSignedUrlData: " + JSON.stringify(PreSignedUrlData, null, 2));
    if(PreSignedUrlData && PreSignedUrlData?.GeneratePresignedUrl?.success) {
      setSelectedMapUrl(PreSignedUrlData?.GeneratePresignedUrl?.url);
      const newWindow = window.open(PreSignedUrlData?.GeneratePresignedUrl?.url, '_blank');
        if (!newWindow) {
          console.error("Failed to open new window. It might be blocked by the browser.");
        }
    }
  }, [PreSignedUrlData]);

  useEffect(() => {
    console.log("useEffect selectedMapUrl: " + selectedMapUrl);
  }, [selectedMapUrl]);

  return (
    <>
      <h5 className="mapsectiontitle">Maps</h5>
      {/* <Dropdown overlay={menu} placement="bottomLeft" arrow>
        <Button>Select site</Button>
      </Dropdown> */}
      {/* if you want to use select uncomment select and comment dropdown */}
      <div className="container-fluid">
        <div className="row">
          <div className="form-group col-md-3">
            <Select style={{ width: "100%" }} placeholder="Select Site" onChange={onChangeSearch}>
              <Select.Option key="All" value="">
                All
              </Select.Option>
              {SiteList?.GetSites?.results?.map((_site) => (
                <Select.Option key={_site._id} value={_site._id} site={_site}>
                  {_site.code}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="form-group col-md-3 ml-auto">
            <Link to={match.path + "/create-map"} className="btn btn-primary">
              Add Map <i className="fas fa-plus-circle"></i>
            </Link>
          </div>
        </div>
      </div>
      <DynamicList
        columns={columns}
        data={results}
        loading={loading}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        }
      />

      <Modal
        title={" Re-Upload"}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <form>
          <div className="form-group col-md-12">
            <Upload {...props} maxCount={1}>
              <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                Upload
              </Button>
            </Upload>
            <div hidden={isSpinHidden} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Spin tip="Uploading..."></Spin>
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        title={selectedMap?.mapName}
        onCancel={() => setMapModal(false)}
        visible={mapModal}
        width={1000}
        footer={null}
      >
        <img alt="state" src={formatImage(selectedMap?.mapName)} style={{ width: "100%" }} />
      </Modal>
    </>
  );
};
