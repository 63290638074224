import { UserRole } from "../config";

export const sidebar = {
  [UserRole.SuperAdmin]: [
    // {
    //   path: "/dashboard",
    //   label: "Dashboard",
    //   icon: "fas fa-tachometer",
    // },
    {
      path: "/sites",
      label: "Sites",
      icon: "fas fa-sitemap",
    },
    {
      path: "/maps",
      label: "Maps",
      icon: "far fa-map",
    },
    {
      path: "/users",
      label: "Users",
      icon: "far fa-user",
    },
    {
      path: "/permits",
      label: "All Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/childpermits",
      label: "All Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/templates",
      label: "All Templates",
      icon: "fal fa-file",
    },
    {
      path: "/disciplines",
      label: "All Disciplines",
      icon: "fal fa-file",
    },
    {
      path: "/mtgroup",
      label: "MT Group",
      icon: "far fa-columns",
      exact: true,
    },
    {
      path: "/blocked-words",
      label: "Blocked Words",
      icon: "fa fa-ban",
      exact: true,
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
    {
      path: "/sipp-works",
      label: "SIPP Daily Reports",
      icon: "fa fa-list-alt",
      exact: true,
    },
  ],

  [UserRole.SiteAdmin]: [
    {
      path: "/users",
      label: "Users",
      icon: "far fa-user",
    },
    {
      path: "/mtgroup",
      label: "MT Groups",
      icon: "far fa-user",
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
    {
      path: "/sipp-works",
      label: "SIPP Daily Reports",
      icon: "fa fa-list-alt",
      exact: true,
    },
  ],

  [UserRole.MTGroup]: [
    {
      path: "/all-my-permits",
      label: "My Submitted Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/my-child-permit",
      label: "My Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/my-draft-permits",
      label: "My Draft Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/my-pending-approval-permits",
      label: "Permits Pending My Approval",
      icon: "fal fa-clipboard",
    },
    {
      path: "/permits",
      label: "Other Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/childpermits",
      label: "All Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/lss-tab-permits",
      label: "LSS Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/permits-i-approved",
      label: "Past Permits I Approved",
      icon: "fal fa-clipboard",
    },
    {
      path: "/my-child-permits-pending-approval",
      label: "My Child Permits Pending Approval",
      icon: "fal fa-clipboard",
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
    {
      path: "/sipp-works",
      label: "SIPP Daily Reports",
      icon: "fa fa-list-alt",
      exact: true,
    },
  ],
  [UserRole.MicronSupervisor]: [
    {
      path: "/permits",
      label: "All Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/childpermits",
      label: "All Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
    {
      path: "/sipp-works",
      label: "SIPP Daily Reports",
      icon: "fa fa-list-alt",
      exact: true,
    },
  ],
  [UserRole.MicronManager]: [
    {
      path: "/permits",
      label: "All Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/childpermits",
      label: "All Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
    {
      path: "/sipp-works",
      label: "SIPP Daily Reports",
      icon: "fa fa-list-alt",
      exact: true,
    },
  ],
  [UserRole.ContractorPM]: [
    {
      path: "/permits",
      label: "All Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/childpermits",
      label: "All Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
  ],
  [UserRole.User]: [
    {
      path: "/permits",
      label: "My Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/childpermits",
      label: "My Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/my-draft-permits",
      label: "My Draft Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
  ],
  [UserRole.ContractorRequestor]: [
    {
      path: "/permits",
      label: "My Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/childPermits",
      label: "My Child Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/my-draft-permits",
      label: "My Draft Permits",
      icon: "fal fa-clipboard",
    },
    {
      path: "/initiatives",
      label: "ePTW Initiatives",
      icon: "fa fa-tree",
      exact: true,
    },
  ],
};

export const getSidebar = (role) => {
  return sidebar[role];
};
