import {
  Radio,
  Checkbox,
  TimePicker,
  DatePicker,
  Upload,
  message,
  Button,
  Select,
  Input,
  Modal,
  Spin,
  Tooltip,
  Form,
} from "antd";
import React, { Fragment, useRef, useState, useEffect } from "react";
import { UploadOutlined, FileImageOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import {
  FILE_UPLOAD,
  DELETE_FILE,
  GET_MAP_LIST,
  GET_ALL_MICRON_USERS,
  GET_GROUP_LIST,
  GET_SINGLE_USER,
  GET_MTGROUP_BY_ID,
  GENERATE_PRESIGNED_URL
} from "../../graphql/modules";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";
import { fabric } from "fabric";
import { formatImage } from "../../util";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import { PermitStatus, deleteButtonToolTip } from "../../config";

import { UltimateTextToImage } from "ultimate-text-to-image";

import mergeImages from "merge-base64";

// import { useForm, Controller } from "react-hook-form";

const { Search } = Input;

const { RangePicker } = DatePicker;

export const PermitFormItem = (
  {
    permitStatus,
    templateKey,
    item,
    state,
    onChange = () => {},
    siteID,
    formState,
    handleCheckForUploadFile = () => {},
    formRef,
    permitIndivudualReviewers,
    permitMtGroupReviewers
  },
  isParent
) => {
  const { user: authUser } = useSelector((_state) => _state.auth);
  const [fileList, setFileList] = useState([]);
  const [FileUpload, { loading: uploadLoading }] = useMutation(FILE_UPLOAD);
  const [DeleteFile, { loading: deleteLoading }] = useMutation(DELETE_FILE);
  const [signModal, setSignModal] = useState(false);
  const signatureRef = useRef();

  const [childState, setChildState] = useState(state);
  const [checkedList, setCheckedList] = useState([]);
  const [fromParent, setFromParent] = useState(isParent);

  const [childDisabledState, setChildDisabledState] = useState();

  const [isSpinHidden, setIsSpinHidden] = useState(true);

  const [visible, setVisible] = useState(false);
  const [limit] = useState(10);
  const [page, setPage] = useState(1);
  const [mapModel, setMapModal] = useState(false);
  const mapRef = useRef();
  const [mapName, setMapName] = useState("");
  const [canvas, setCanvas] = useState("");
  const [clearCanvas, setCanvasClear] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);

  const [reviewerId, setReviewerId] = useState("");
  const [isReviewerMTGroup, setIsReviewerMTGroup] = useState(false);

  const [allReviewers, setAllReviewers] = useState([]);

  const datePickerRef = useRef();

  const [getPresignedUrl, {data: PreSignedUrlData }] = useLazyQuery(GENERATE_PRESIGNED_URL);

  useEffect(() => {
    console.log("useEffect PreSignedUrlData: " + JSON.stringify(PreSignedUrlData, null, 2));
    if(PreSignedUrlData && PreSignedUrlData?.GeneratePresignedUrl?.success) {
      const newWindow = window.open(PreSignedUrlData?.GeneratePresignedUrl?.url, '_blank');
      if (!newWindow) {
        console.error("Failed to open new window. It might be blocked by the browser.");
      }
    }
  }, [PreSignedUrlData]);

  const fetchPresignedUrl = async (filename) => {
    console.log("fetchPresignedUrl filename: " + filename);
    try {
      getPresignedUrl({ variables: { filename: filename } });
      console.log("fetchPresignedUrl data: " + JSON.stringify(data, null, 2));
    } catch (error) {
      console.error("Error fetching pre-signed URL:", error);
      return null;
    }
  };

  const handleGetPresignedUrlData = async (filename) => {
    console.log("handleGetPresignedUrlData: " + filename);
    fetchPresignedUrl(filename);
  };

  useEffect(() => {
    try {
      if (item?.type === "file") {
        setFileList(
          state[item?.name]?.map((res, key) => {
            return {
              uid: key,
              name: res,
              url: process.env.REACT_APP_IMAGE_URL + res,
            };
          })
        );
      }
    } catch {
      console.log("Error");
    }
  }, [isParent]);

  const [getGroups, { data: GroupList, loading: fetchingGroup }] = useLazyQuery(GET_GROUP_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: "@micron.com",
    },
  });

  const { data: UserData, refetch: refetchUser } = useQuery(GET_SINGLE_USER, {
    variables: {
      id: reviewerId,
    },
  });

  const { data: MTGroupData, refetch: refetchMTGroup } = useQuery(GET_MTGROUP_BY_ID, {
    variables: {
      id: reviewerId,
    },
  });

  const SignatoryInfo = ({ id }) => {
    // console.log("SignatoryInfo:id: " + id);
    handleSetReviewerId(id);
    let rValue = "";
    let styleBorderColor = "grey";

    // console.log("authUser: " + JSON.stringify(authUser, null, 2));

    if (UserData?.GetUser?.user) {
      let user = UserData?.GetUser?.user;
      // console.log("user: " + JSON.stringify(user, null, 2));
      if (authUser._id === user?._id) {
        styleBorderColor = "red";
      }
      rValue = user?.email;
    } else if (MTGroupData?.GetMTGroupById?.results) {
      let mtgroup = MTGroupData?.GetMTGroupById?.results;
      // console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));
      if (
        mtgroup?.members?.filter(function (e) {
          return e._id === authUser._id;
        }).length > 0
      ) {
        styleBorderColor = "red";
      }
      rValue = mtgroup?.name;
    } else {
      // if id has previous text value, display it.
      if (id) {
        rValue = id;
      } else {
        rValue = "N/A";
      }
    }

    return (
      <input
        style={{ borderColor: styleBorderColor }}
        type={item.type}
        className="form-control"
        placeholder={item.placeholder}
        name={item.name}
        value={rValue}
      />
    );
  };

  useEffect(() => {
    refetchUser();
    refetchMTGroup();
  }, [reviewerId]);

  const handleSetReviewerId = (id) => {
    // console.log("handleSetReviewerId: " + id);
    setReviewerId(id);
    // console.log("reviewerId: " + reviewerId);
  };

  const onSearchUser = React.useMemo(() => {
    const loadOptions = (value) => {
      getUsers({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onSearchGroup = React.useMemo(() => {
    const loadOptions = (value) => {
      getGroups({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onChangeReviewerCheckBox = (e) => {
    // console.log("checked = ", e.target.checked);
    setIsReviewerMTGroup(e.target.checked);
  };

  useEffect(() => {
    setFromParent(isParent);
  }, [isParent]);

  const canvasToBlob = (canvas) => {
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  };

  const onChildChange = async (obj, itemName) => {
    setChildState({ ...childState, ...obj });
    onChange(obj);
  };

  // const onChildDisabledChange = async (obj, itemName) => {
  //   setChildDisabledState({ ...childDisabledState, ...obj });
  // };

  const onSaveSignature = async () => {
    var canvas = signatureRef.current;

    var today = new Date();
    var todayUTCText = today.toLocaleString("en-SG");
    const textToImage = new UltimateTextToImage(todayUTCText, {
      width: 500,
      height: 200,
      alignToCenterIfLinesLE: 1,
      fontSize: 20,
    }).render();
    const dataUrlPng = textToImage.toDataUrl();

    const dataUrl = canvas.toDataURL("image/png");

    let subStr = "data:image/png;base64,";
    var signUrl = dataUrl.substring(subStr.length);
    var dateUrl = dataUrlPng.substring(subStr.length);

    const mergedImage = await mergeImages([signUrl, dateUrl], {
      align: "center",
      color: "rgba(255,255,255,1)",
      offset: -500,
    });
    var convertedMergedImg = mergedImage.replace("data:image/jpeg", "data:image/png");
    onChildChange({ [item.name]: convertedMergedImg }, item.name);
    // setSignModal(false);
  };

  const onChangeSignatureDate = async () => {
    // OnSaveSignatureAddDate();
    setSignModal(false);
  };

  const OnSaveSignatureAddDate = async () => {
    console.log("OnSaveSignatureAddDate signNameDate: " + item?.name);

    try {
      if (item?.name?.includes("sign_")) {
        var dateSigned = item?.name.replace("sign_", "date_");
        var formattedDateToday = moment(new Date()).format("YYYY-MM-DD");

        console.log("OnSaveSignatureAddDate dateSigned: " + dateSigned);
        console.log("OnSaveSignatureAddDate formattedDateToday: " + formattedDateToday);

        var el = document.getElementById(dateSigned);
        el.value = formattedDateToday;
        doEvent(el, "input", dateSigned, formattedDateToday);
      }
    } catch (e) {
      console.error("error on OnSaveSignatureAddDate: " + e);
    }
  };

  const doEvent = async (obj, event, field, value) => {
    var event = new Event(event, { target: obj, bubbles: true });
    onChildChange({ [field]: value }, field);
    return obj ? obj.dispatchEvent(event) : false;
  };

  useEffect(() => {
    console.log("childState: ", JSON.stringify(childState, null, 2));
  }, [childState]);

  const clear = () => {
    signatureRef.current.clear();
    onChildChange({ [item.name]: undefined });
  };
  const { data, loading, error, refetch } = useQuery(GET_MAP_LIST, {
    variables: {
      limit,
      offset: page - 1,
    },
  });

  const handleData = data?.GetMap?.results?.filter((filterData) => {
    try {
      if (siteID === "") {
        return filterData;
      } else {
        return filterData.site.toLowerCase().indexOf(siteID) !== -1;
      }
    } catch (err) {
      console.log(err);
    }
  });

  function onChangeMapName(mapImage) {
    if (clearCanvas !== "") {
      try {
        clearCanvas.dispose();
      } catch (e) {
        console.error("msg: no canvas to dispose");
      }
    }
    const c = mapRef.current;
    const canvas = new fabric.Canvas(c, {
      isDrawingMode: true,
    });
    canvas.freeDrawingBrush.color = "red";
    canvas.freeDrawingBrush.width = 10;
    canvas.setHeight(600);
    canvas.setWidth(1000);
    const mapUrl = process.env.REACT_APP_IMAGE_URL + mapImage;
    fabric.Image.fromURL(
      mapUrl,
      function (myImg) {
        canvas.setBackgroundImage(myImg, canvas.renderAll.bind(canvas), {
          width: canvas.width,
          height: canvas.height,
          originX: "left",
          originY: "top",
        });
      },
      { crossOrigin: "anonymous" }
    );

    canvas.renderAll();
    setCanvasClear(canvas);
  }
  const clearMap = () => {
    if(clearCanvas) {
      clearCanvas.remove(...clearCanvas.getObjects());
    } else {
      console.log("No canvas to clear");
    }

  };
  const onSaveMap = async () => {
    clearCanvas.getElement().toBlob(async (blob) => {
      let file = new File([blob], "mapDemacartion.png", { type: "image/png" });
      try {
        const {
          data: { SingleUpload },
        } = await FileUpload({
          variables: {
            file,
          },
        });
        file.url = SingleUpload?.url;
        file.filename = SingleUpload?.filename;

        // onChange({[item?.name]: [...item?.name, SingleUpload?.filename] });
        // onChange({...fileList, [item?.name]: file.filename });
        onChange({ [item.name]: [...(formState[item?.name] || []), SingleUpload?.filename] });
        setMapModal(false);
      } catch (error) {
        console.log(error);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
        setIsSpinHidden(true);
      }
    }, "image/jpeg");

    setSignModal(false);
    return;
  };

  const onCheckItem = (e, option) => {
    let newArr = checkedList;

    if (newArr) {
      if (newArr.some((o) => o === option.value)) {
        if (!e.target.checked) {
          newArr.splice(newArr.indexOf(option.value), 1);
        }
      } else {
        if (e.target.checked) {
          newArr.push(option.value);
        }
      }
    } else {
      newArr = [option.value];
    }

    setCheckedList(newArr);
  };

  const handleChildStateFromParent = async (value, itemName) => {
    onChildChange({ [itemName]: value }, itemName);
    return value;
  };

  useEffect(() => {
    let combineAllReviewers = permitIndivudualReviewers?.concat(permitMtGroupReviewers);
    setAllReviewers(combineAllReviewers);
  }, [permitIndivudualReviewers, permitMtGroupReviewers]);

  let isCurrentUserReviewer = allReviewers?.some((reviewer) => reviewer._id === authUser._id);
  // console.log("isCurrentUserReviewer: " + isCurrentUserReviewer);

  let showDeleteIcon = permitStatus === PermitStatus.Rejected && !isCurrentUserReviewer ? true : false;
  // console.log("showDeleteIcon: " + showDeleteIcon);

  const props = {
    name: "file",
    customRequest: async ({ file, onSuccess }) => {
      try {
        const {
          data: { SingleUpload },
        } = await FileUpload({
          variables: {
            file,
          },
        });
        file.url = SingleUpload?.url;
        file.filename = SingleUpload?.filename;
        onSuccess("done");
      } catch (error) {
        console.log(error);
      }
    },
    showUploadList: {
      showRemoveIcon: showDeleteIcon,
    },
    onPreview: async (file) => {
      if(file.filename) {
        handleGetPresignedUrlData(file.filename);
      } else {
        handleGetPresignedUrlData(file.name);
      }
    },
    onRemove: async (file) => {
      console.log("onRemove file: " + JSON.stringify(file, null, 2));
      try {
        if (file.filename !== undefined) {
          await DeleteFile({
            variables: {
              filename: file.filename,
            },
          });
        }
        const files = fileList.filter((f) => f.uid !== file.uid);
        console.log("onRemove files: " + JSON.stringify(files, null, 2));
        // var stateFiles;
        var formData = formState !== undefined ? formState[item?.name] : childState[item?.name];
        onChange({ [item.name]: formData.filter((f) => f !== file.filename) });
        setFileList(files);
      } catch (error) {}
    },
    beforeUpload: (file) => {
      var fileToUpload = fileList ? fileList : [];
      setFileList([...fileToUpload, file]);
    },
    // multiple: false,
    // listType: "picture",
    onChange(info, list) {
      console.log("onChange file info: " + JSON.stringify(info, null, 2));
        // get all the filename from fileList amd pass it to onChange
      if (info.file.status === "uploading") {
        setIsSpinHidden(false);
      }
      if (info.file.status === "removed") {
        var formData = formState !== undefined ? formState[item?.name] : childState[item?.name];
        console.log(info.file.name);

        // var stateFiles = formData.filter((f) => f !== info.file.name);
        // onChange({ [item.name]: stateFiles });
        onChange({ [item.name]: formData.filter((f) => f !== info.file.name) });
        // onChildChange({ [item.name]: formData.filter((f) => f !== info.file.name) });
      }
      if (info.file.status === "done") {
        setIsSpinHidden(true);
        message.success(`File uploaded successfully`);
        var formData = formState !== undefined ? formState[item?.name] : childState[item?.name];
        onChange({ [item.name]: [...(formData || []), info.file.filename] });
        onChildChange({ [item.name]: [...(formData || []), info.file.filename] });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }

      if(info.fileList) {
        // create a tmp array variable named updatedFiles
        // get all objects from fileList with name and pass it to updatedFiles,
        // get all objects from fileList with status = done and pass it to updatedFiles
        // filter unique filename and name from the updatedFiles and pass it to a tmp variable
        // then pass the tmp variable to the onChange function

        var oldFiles = info?.fileList?.filter((f) => f.name && f.status === undefined).map((f) => f.name);
        var doneFiles = info?.fileList?.filter((f) => f.status === "done").map((f) => f.filename);
        var mergedFiles = [...oldFiles, ...doneFiles];
        onChange({ [item.name]: mergedFiles });

      }

    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  let revId = "";
  let reviewerInfo = "";
  let input = JSON.stringify(item);

  let inputProps = {};

  if (item?.type === "textarea") {
    input = (
      <textarea
        rows={5}
        type={item?.type}
        className="form-control"
        placeholder={item?.placeholder}
        name={item?.name}
        onChange={(e) => onChildChange({ [item.name]: e.target.value }, item?.name)}
        value={childState[item?.name] ? childState[item?.name] : "N/A"}
        required={item?.required}
        {...inputProps}
      />
    );
  } else if (item?.type === "select") {
    input = (
      <Select
        placeholder={item?.label}
        style={{ width: "100%" }}
        type="name"
        size="large"
        className="form-control-select"
        name="label"
        onChange={(value) => onChildChange({ [item.name]: value }, item?.name)}
        showSearch
        // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={childState?.[item?.name] ? childState?.[item?.name] : null}
        required={item?.required}
        {...inputProps}
      >
        {item?.options?.map((option, key) => (
          <Select.Option key={key} value={option?.value}>
            {option?.label}
          </Select.Option>
        ))}
      </Select>
    );
  } else if (item?.type === "radio") {
    input = (
      <Fragment>
        <label className="w-100">{item?.label}</label>
        <Radio.Group
          name={item?.name}
          onChange={(e) => onChildChange({ [item.name]: e.target.value }, item?.name)}
          value={childState[item?.name] ? childState[item?.name] : null}
          required={item?.required}
          {...inputProps}
        >
          {item?.options?.map((option, key) => (
            <Radio style={radioStyle} value={option?.value} key={key}>
              {option?.label}
            </Radio>
          ))}
        </Radio.Group>
      </Fragment>
    );
  } else if (item?.type === "checkbox") {
    input = (
      <div className="form-group">
        <Checkbox
          onChange={(e) => {
            onChildChange({ [item.name]: e.target.checked }, item?.name);
          }}
          checked={childState[item?.name]}
          required={item?.required}
        >
          {item?.label}
        </Checkbox>
      </div>
    );
  } else if (item?.type === "multicheck") {
    input = (
      <div className="">
        <label htmlFor="required" className="cursor-pointer">
          {item?.label}
        </label>
        {item?.options?.map((option, index) => {
          return (
            <Checkbox
              key={index}
              style={radioStyle}
              onChange={(e) => {
                e.preventDefault();
                if (fromParent) {
                  // console.log("Multi Check Change from Parent Permit");
                  onCheckItem(e, option);
                  // console.log("before onChange" + checkedList);
                  onChildChange({ [item.name]: checkedList }, item?.name);
                } else if (!fromParent) {
                  // console.log("Multi Check Change from Child Permit");
                  /** OLD WORKING CODE FOR CHILD PERMIT */
                  let values = checkedList;

                  if (values) {
                    if (values?.some((o) => o === option?.value)) {
                      if (!e.target.checked) {
                        values?.splice(values?.indexOf(option?.value), 1);
                      }
                    } else {
                      if (e.target.checked) {
                        values?.push(option?.value);
                      }
                    }
                  } else {
                    values = [option?.value];
                  }

                  setCheckedList(values);
                  onChildChange({ [item.name]: checkedList }, item?.name);
                }
              }}
              checked={childState?.[item?.name]?.includes(option?.value)}
              required={item?.required}
            >
              {option?.label}
            </Checkbox>
          );
        })}
      </div>
    );
  } else if (item?.type === "time") {
    input = (
      <div className="">
        <TimePicker
          className="form-control"
          placeholder={item.label}
          value={childState[item?.name] ? moment(childState[item?.name] || "00:00", "HH:mm") : null}
          format={"HH:mm"}
          onChange={(_moment, timestring) => onChildChange({ [item.name]: timestring }, item?.name)}
          required={item.required}
        />
      </div>
    );
  } else if (item?.type === "date") {
    input = (
      <Form.Item
        name={item?.name}
        rules={[
          {
            validator: async (_, value) => {
              console.log("validator value: " + value);
              if (item?.required) {
                if (childState[item?.name] === null || childState[item?.name] === "") {
                  return Promise.reject("This signature field is required.");
                }
                return Promise.resolve();
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          id={item?.name}
          className="form-control"
          placeholder={item?.label}
          onChange={(_moment, timestring) => onChildChange({ [item.name]: timestring }, item?.name)}
          defaultValue={childState[item?.name] ? moment(childState[item?.name]) : null}
          value={childState[item?.name] ? moment(childState[item?.name]) : null}
          required={item?.required}
          ref={datePickerRef}
        />
      </Form.Item>
    );
  } else if (item?.type === "daterange") {
    input = (
      <div>
        <RangePicker
          className="form-control"
          // onChange={(_moment, timestring) => {
          //   console.log(timestring, state);
          //   onChange({ [item.name]: timestring });
          // }}
          value={
            childState[item?.name]?.length ? [moment(childState[item?.name][0]), moment(childState[item?.name][1])] : []
          }
          required={item?.required}
        />
      </div>
    );
  } else if (item?.type === "file") {
    input = (
      <div id="PermitUpload">
        <Form.Item
          name={item?.name}
          dependencies={[item?.name]}
          rules={[
            {
              validator: async (_, value) => {
                if (item?.required) {
                  if (!Array.isArray(childState[item?.name]) || !childState[item?.name].length) {
                    return Promise.reject("This file is required.");
                  }
                  return Promise.resolve();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload {...props} className="w-100 d-block" fileList={fileList}>
            <Button className="form-control" icon={<UploadOutlined />}>
              {item?.placeholder || "Click to upload"}
            </Button>
          </Upload>
        </Form.Item>

        <div hidden={isSpinHidden} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Spin tip="Uploading..."></Spin>
        </div>
      </div>
    );
  } else if (item.type === "esign") {
    input = (
      <div className="" id="PermitUpload">
        <Form.Item
          name={item?.name}
          dependencies={[item?.name]}
          rules={[
            {
              validator: async (_, value) => {
                console.log("validator value: " + value);

                if (item?.required) {
                  if (childState[item?.name] === null) {
                    return Promise.reject("This signature field is required.");
                  }
                  return Promise.resolve();
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Button
            // disabled={disableSign}
            icon={<UploadOutlined />}
            className="form-control"
            size="large"
            onClick={() => setSignModal(true)}
          >
            {item?.label}
          </Button>
          {state[item?.name] && <p className="text-info mt-1">Signature added</p>}
          <Modal
            visible={signModal}
            onCancel={() => setSignModal(false)}
            title="Draw Signature"
            onOk={onChangeSignatureDate}
            okText="Save"
          >
            <div style={{ overflow: "hidden" }}>
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 500,
                  height: 200,
                  className: "sigCanvas",
                  style: { background: "rgba(0,0,0,0)" },
                }}
                onEnd={onSaveSignature}
                ref={signatureRef}
              />
            </div>
            <button className="form-control" onClick={clear}>
              Clear{" "}
            </button>
          </Modal>
        </Form.Item>
      </div>
    );
  } else if (item?.type === "map") {
    input = (
      <div className="" id="PermitUpload">
        <Button
          icon={<FileImageOutlined />}
          className="form-control"
          size="large"
          onClick={() => {
            setMapModal(true);
          }}
        >
          {item?.label}
        </Button>
        {Array.isArray(formState[item?.name])
          ? formState[item?.name].map((filename) => {
              if (
                filename?.split(".").pop() === "jpg" ||
                filename?.split(".").pop() === "png" ||
                filename?.split(".").pop() === "jpeg"
              ) {
                return (
                  <img
                    alt="state"
                    width="100"
                    src={formatImage(filename)}
                    onClick={() => setVisible(filename)}
                    style={{ cursor: "pointer" }}
                    className="mr-2 mb-2"
                  />
                );
              } else {
                return (
                  <div>
                    <a href="#" 
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        handleGetPresignedUrlData(filename);
                      }}
                    >
                      <span>{filename}</span>
                    </a>
                    <hr />
                  </div>
                );
              }
            })
          : "N/A"}
        {item?.type === "map" && (
          <Modal
            title={item?.label}
            onCancel={() => setVisible(false)}
            footer={[
              <Button
                key="back"
                onClick={() => {
                  setVisible(false);
                  try {
                    DeleteFile({
                      variables: {
                        filename: visible,
                      },
                    });
                    const stateFiles = formState[item?.name]?.filter((f) => f !== visible);
                    onChange({ [item.name]: stateFiles });
                    setVisible(false);
                  } catch (error) {
                    console.log("onRemove");
                    console.log(error);
                  }
                }}
              >
                Delete
              </Button>,
              <Button key="back" type="primary" onClick={() => setVisible(false)}>
                Ok
              </Button>,
            ]}
            visible={visible}
          >
            <img alt="state" src={formatImage(visible)} />
          </Modal>
        )}
        <Modal
          visible={mapModel}
          onCancel={() => setMapModal(false)}
          title="Map"
          onOk={onSaveMap}
          okText="Save"
          width={1000}
        >
          <div style={{ overflow: "hidden" }}>
            <div className="row">
              <div className="form-group col-md-6">
                <Select style={{ width: "100%" }} placeholder="Select Map" onChange={onChangeMapName}>
                  {handleData?.map((_map) => (
                    <Select.Option key={_map?._id} value={_map?.mapImage}>
                      {_map?.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>

            <canvas ref={mapRef} />
          </div>
          <button className="form-control" onClick={clearMap}>
            Clear
          </button>
        </Modal>
      </div>
    );
  } else if (item.type === "reviewerlist") {
    let disableField = permitStatus === PermitStatus.Rejected ? false : true;

    if (disableField) {
      input = <SignatoryInfo id={childState[item?.name]} />;
    } else {
      input = (
        <Fragment>
          {isReviewerMTGroup ? (
            <Select
              style={{ width: "100%" }}
              onChange={(value) => onChildChange({ [item.name]: value })}
              placeholder="Select New MTGroup"
              showSearch={true}
              onSearch={onSearchGroup}
              filterOption={false}
              notFoundContent={fetchingGroup ? <Spin size="small" /> : null}
              allowClear
              onClear={() => onChildChange({ [item.name]: "" })}
            >
              {GroupList?.GetUserGroups?.results
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((group) => (
                  <Select.Option key={group._id} value={group._id}>
                    {group.name}
                  </Select.Option>
                ))}
            </Select>
          ) : (
            <Select
              style={{ width: "100%" }}
              onChange={(value) => onChildChange({ [item.name]: value })}
              placeholder="Select New User"
              showSearch={true}
              onSearch={onSearchUser}
              filterOption={false}
              notFoundContent={fetchingUser ? <Spin size="small" /> : null}
              allowClear
              onClear={() => onChildChange({ [item.name]: "" })}
            >
              {UserList?.GetAllMicronUsers?.results
                ?.sort((a, b) => a.email.localeCompare(b.email))
                .map((user) => (
                  <Select.Option key={user._id} value={user._id}>
                    <strong>{user.fullname}</strong>
                    <br />
                    <span>{user.email}</span>
                  </Select.Option>
                ))}
            </Select>
          )}
          <Checkbox
            style={{ marginTop: "2%" }}
            checked={isReviewerMTGroup}
            onChange={(e) => {
              e.preventDefault();
              onChangeReviewerCheckBox(e);
              onChildChange({ [item.name]: "" });
            }}
          >
            Show MTGroups
          </Checkbox>
        </Fragment>
      );
    }
  } else if (item?.type === "freetext") {
    input = <div className="w-100">{item?.label}</div>;
  } else {
    input = (
      <Form.Item
        name={item?.name}
        rules={[
          {
            validator: async (_, value) => {
              console.log("validator value: " + value);
              if (item?.required) {
                if (childState[item?.name] === null || childState[item?.name] === "") {
                  return Promise.reject("This signature field is required.");
                }
                return Promise.resolve();
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <input
          type={item?.type}
          className="form-control"
          placeholder={item?.placeholder}
          name={item?.name}
          onChange={(e) => onChildChange({ [item.name]: e.target.value }, item?.name)}
          defaultValue={childState[item?.name] ? childState[item?.name] : ""}
          value={childState[item?.name] ? childState[item?.name] : ""}
          required={item?.required}
          {...inputProps}
          // disabled={value}
        />
      </Form.Item>
    );
  }
  return (
    <div id="permit-input" className={`form-group col-md-${item?.col}`}>
      {input}
      {item?.required ? <small className="text-danger d-block">Required</small> : ""}
    </div>
  );
};

const radioStyle = {
  display: "block",
  // height: "30px",
  lineHeight: "30px",
  fontWeight: "normal",
  marginLeft: 0,
  marginBottom: 15,
};
