import { Modal, Select, Input } from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PermitStatus, UserRole } from "../../config";
import { formatImage } from "../../util";
import { PermitUserInfo } from "./PermitUserInfo";
import { GET_SINGLE_USER, GET_MTGROUP_BY_ID, GENERATE_PRESIGNED_URL } from "../../graphql/modules";
import _ from "lodash";

export const PermitSingle = ({ permit = {}, onUpdate = () => {}, updateLoading, isChildren, querySearch }) => {
  const { user: authUser } = useSelector((state) => state.auth);
  const [status, setStatus] = useState("");
  const form = permit?.form;
  const history = useHistory();

  useEffect(() => {
    setStatus(permit?.status);
  }, [permit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate({ status });
  };

  const handleStatusUpdate = ({ status }) => {
    setStatus(status);
    onUpdate({ status });
  };

  const approvedBy = permit.approvedBy?.find((user) => user._id === authUser._id);
  const lastApprovedBy = permit?.approvedBy?.length ? permit.approvedBy[permit?.approvedBy?.length - 1] : {};
  const rejectedBy = permit.rejectedBy;

  // let showUpdateDraftBtn = false;
  let backURL = "/permits";

  if (permit?.status === PermitStatus.DraftPermit) {
    backURL = "/my-draft-permits";
    // showUpdateDraftBtn = true;
  }

  // let selectMtValue = permit?.selectedMTGroupUsers?.map((user) => {
  //   return user.email;
  // });

  console.log("PERMIT HOST OWNER: \n" + JSON.stringify(permit?.hostSystemOwner))

  let selectedMTGroup = permit?.selectedMTGroup?.map((grp) => {
    return grp?.name;
  });

  let selectDefaultValue = permit?.selectedUser?.map((user) => {
    if (!permit?.selectedMTGroupUsers?.find((o2) => user.email === o2.email)) {
      return user?.email;
    }
  });

  let selectHostOwnerValue = permit?.hostSystemOwner?.email
  let selectDisciplineValue = permit?.discipline?.code

  const selectedHostOwnerProps = {
    style: { width: "100%" },
    value: selectHostOwnerValue,
  };

  const selectedDisciplineProps = {
    style: { width: "100%" },
    value: selectDisciplineValue,
  };

  const selectedProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: _.compact(selectDefaultValue),
  };

  const selectedMtProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: selectedMTGroup,
  };

  let showActionBtn = false;
  if (status !== PermitStatus.Rejected) {
    if (authUser.role === UserRole.SuperAdmin) {
      // Show button for superadmin
      showActionBtn = true;
    } else if (authUser.role === UserRole.ContractorPM && status === PermitStatus.Pending) {
      // Show button for Contractor PM
      showActionBtn = true;
    } else if (authUser.role === UserRole.MTGroup && authUser._id !== permit?.submittedBy?._id && !approvedBy) {
      // Show button for MT Group
      showActionBtn = true;
    }
  }

  const PermitSingleSection = ({ section, submission = {} }) => {
    return (
      <div className="card full-details">
        <h2>{section.title}</h2>
        <div className="details-wrapper ">
          {section?.form?.map((item, key) => (
            <PermitItem key={key} item={item} submission={submission} />
          ))}
        </div>
      </div>
    );
  };

  const PermitItem = ({ item, submission }) => {
    const [visible, setVisible] = useState(false);
    const [reviewerId, setReviewerId] = useState(null);

    const { data: UserData, refetch: refetchUser } = useQuery(GET_SINGLE_USER, {
      variables: {
        id: reviewerId,
      },
    });

    const { data: MTGroupData, refetch: refetchMTGroup } = useQuery(GET_MTGROUP_BY_ID, {
      variables: {
        id: reviewerId,
      },
    });

    const [getPresignedUrl, {data: PreSignedUrlData }] = useLazyQuery(GENERATE_PRESIGNED_URL);

    const handleSetReviewerId = (id) => {
      setReviewerId(id);
    };

    const SignatoryInfo = ({ id }) => {
      handleSetReviewerId(id);
      let reviewerDetails = "";

      let user = UserData?.GetUser ? UserData?.GetUser?.user : null;
      let mtgroup = MTGroupData?.GetMTGroupById ? MTGroupData?.GetMTGroupById?.results : null;

      if (user) {
        console.log("user: " + JSON.stringify(user, null, 2));
        reviewerDetails = user?.email;
      } else if (mtgroup) {
        console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));
        reviewerDetails = mtgroup?.name;
      } else {
        reviewerDetails = "N/A";
      }

      return <p>{reviewerDetails}</p>;
    };

    /** useEffect used for useQuery */
    useEffect(() => {
      console.log("useEffect, change of reviewerId: " + reviewerId);
      refetchUser();
      refetchMTGroup();

      console.log("user: " + JSON.stringify(UserData?.GetUser?.user, null, 2));
      console.log("mtgroup: " + JSON.stringify(MTGroupData?.GetMTGroupById?.results, null, 2));
    }, [reviewerId]);

    const getFileFromAWS = (filename = "") => {
      console.log(filename);
      var imageReg = /[\/.](gif|jpg|jpeg|tiff|png|pdf|xls|xlsx|doc|docx|zip)$/i;
      if (imageReg.test(filename)) {
        return (
          <img
            alt="submission"
            width="100"
            src={formatImage(filename)}
            onClick={() => setVisible(filename)}
            style={{ cursor: "pointer" }}
            className="mr-2 mb-2 "
          />
        );
      } else {
        return (
          <div className="p-3">
            <a href={formatImage(filename)} download target="_blank">
              <i class="fas fa-file fa-3x"></i>
            </a>
          </div>
        );
      }
    };

    const fetchPresignedUrl = async (filename) => {
      // console.log("fetchPresignedUrl filename: " + filename);
      try {
        getPresignedUrl({ variables: { filename: filename } });
      } catch (error) {
        console.error("Error fetching pre-signed URL:", error);
        return null;
      }
    };
  
    const handleGetPresignedUrlData = async (filename) => {
      // console.log("handleGetPresignedUrlData: " + filename);
      fetchPresignedUrl(filename);
    };

    const handleUploadedFileOnclick = (filename) => {
      // console.log("handleUploadedFileOnclick: " + filename);
      handleGetPresignedUrlData(filename);
    }

    useEffect(() => {
      // console.log("useEffect PreSignedUrlData: " + JSON.stringify(PreSignedUrlData, null, 2));
      if(PreSignedUrlData && PreSignedUrlData?.GeneratePresignedUrl?.success) {
        const newWindow = window.open(PreSignedUrlData?.GeneratePresignedUrl?.url, '_blank');
        if (!newWindow) {
          console.error("Failed to open new window. It might be blocked by the browser.");
        }
      }
    }, [PreSignedUrlData]);

    return (
      <div className={`account-details-colum col-md-${item.col}`}>
        <h6>{item.label}</h6>
        {item.type === "file" ? (
          Array.isArray(submission[item.name]) ? (
            submission[item.name].map((filename) => {
              if (
                filename?.split(".").pop() === "jpg" ||
                filename?.split(".").pop() === "png" ||
                filename?.split(".").pop() === "jpeg"
              ) {
                return (
                  <img
                    alt="submission"
                    width="100"
                    src={formatImage(filename)}
                    onClick={() => {
                      setVisible(filename);
                      handleUploadedFileOnclick(filename)
                    }}
                    style={{ cursor: "pointer" }}
                    className="mr-2 mb-2"
                  />
                );
              } else {
                return (
                  <div>
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        handleUploadedFileOnclick(filename)
                      }
                      } 
                      target="_blank"
                    >
                      <span>{filename}</span>
                    </a>
                    <hr />
                  </div>
                );
              }
            })
          ) : (
            "N/A"
          )
        ) : item.type === "esign" ? (
          submission[item.name] ? (
            <img
              alt="submission"
              width="100"
              src={submission[item.name]}
              onClick={() => setVisible(true)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            "N/A"
          )
        ) : item.type === "map" ? (
          Array.isArray(submission[item.name]) ? (
            submission[item.name].map((filename) => {
              if (
                filename?.split(".").pop() === "jpg" ||
                filename?.split(".").pop() === "png" ||
                filename?.split(".").pop() === "jpeg"
              ) {
                return (
                  <img
                    alt="submission"
                    width="100"
                    src={formatImage(filename)}
                    onClick={() => setVisible(filename)}
                    style={{ cursor: "pointer" }}
                    className="mr-2 mb-2"
                  />
                );
              } else {
                return (
                  <div>
                    <a href="#" 
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        handleGetPresignedUrlData(filename);
                      }}
                    >
                      <span>{filename}</span>
                    </a>
                    <hr />
                  </div>
                );
              }
            })
          ) : (
            "N/A"
          )
        ) : Array.isArray(submission[item.name]) ? (
          <p>{submission[item.name].map((val) => val + ", ")}</p>
        ) : item.type === "reviewerlist" ? (
          submission[item.name] ? (
            <SignatoryInfo id={submission[item.name]} />
          ) : (
            " N/A"
          )
        ) : item.type === "freetext" ? (
          <p> {submission[item.name]} </p>
        ) : (
          <p> {submission[item.name] || "N/A"}</p>
        )}

        {(item.type === "file" || item.type === "esign" || item.type === "map") && (
          <Modal title={item.label} visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
            <img alt="submission" src={item.type === "esign" ? submission[item.name] : formatImage(visible)} />
          </Modal>
        )}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="create-permit-details">
            <div className="form-row">
              {permit && (
                <div className="form-group col-md-3">
                  Host/System Owner <br/>
                  <Select {...selectedHostOwnerProps} disabled />
                </div>
              )}
              {permit && (
                <div className="form-group col-md-3">
                  Discipline <br/>
                  <Select {...selectedDisciplineProps} disabled />
                </div>
              )}

              {permit && (
                <div className="form-group col-md-3">
                  Individual Reviewer(s) <br/>
                  <Select {...selectedProps} disabled />
                </div>
              )}

              {permit && (
                <div className="form-group col-md-3">
                  MTGroup(s) <br/>
                  <Select {...selectedMtProps} disabled></Select>
                </div>
              )}
            </div>
          </div>
          {!isChildren && (
            <div className="heading">
              <Link
              onClick={(e) => {
                e.preventDefault();
                history.push(backURL, {
                  query: history.location.state.query
                });
              }}

              className="btn-link">
                <i className="far fa-arrow-left"></i> Back to view all permit <span>[{form?.title}]</span>
              </Link>
            </div>
          )}

          {!isChildren && (
            <PermitUserInfo permit={permit} handleStatusUpdate={handleStatusUpdate} updateLoading={updateLoading} />
          )}

          {form?.sections?.map((section, key) => (
            <PermitSingleSection key={key} section={section} submission={permit?.submission} />
          ))}

          {/* {showUpdateDraftBtn && (
            <div className="account-details-btn">
              <CustomButton className="btn btn-primary">
                <Link to={`review/${permit?._id}`} className="btn-link" style={{ color: "white" }}>
                  Update Draft Permit
                </Link>
              </CustomButton>
            </div>
          )} */}

          {/* {permitted && (
            <div className="account-details-btn">
              <CustomButton type="submit" loading={updateLoading} onClick={handleSubmit} className="btn btn-primary">
                Update
              </CustomButton>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
