import { Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { PermitStatus, UserRole } from "../../config";
import { formatImage } from "../../util";
// import { CustomButton } from "../Shared";
import { ChildUserInfo } from "./ChildUserInfo";
import _ from "lodash";
import { GENERATE_PRESIGNED_URL } from "../../graphql/modules";

export const ChildSingle = ({ permit = {}, onUpdate = () => {}, updateLoading, isChildren }) => {
  const { user: authUser } = useSelector((state) => state.auth);
  const [status, setStatus] = useState("");
  // const [users, setUsers] = useState(null);
  const form = permit?.form;
  const history = useHistory();

  useEffect(() => {
    setStatus(permit?.status);
  }, [permit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate({ status });
  };

  const handleStatusUpdate = ({ status }) => {
    setStatus(status);
    onUpdate({ status });
  };

  const approvedBy = permit.approvedBy?.find((user) => user._id === authUser._id);
  const lastApprovedBy = permit?.approvedBy?.length ? permit.approvedBy[permit?.approvedBy?.length - 1] : {};
  const rejectedBy = permit.rejectedBy;

  // let showUpdateDraftBtn = false;
  let backURL = "/childpermits";

  if (permit?.status === PermitStatus.DraftPermit) {
    backURL = "/my-draft-permits";
    // showUpdateDraftBtn = true;
  }

  // let selectMtValue = permit?.selectedMTGroupUsers?.map((user) => {
  //   return user.email;
  // });

  let selectedMTGroup = permit?.selectedMTGroup?.map((grp) => {
    return grp.name;
  });

  let selectDefaultValue = permit?.selectedUser?.map((user) => {
    if (!permit?.selectedMTGroupUsers?.find((o2) => user.email === o2.email)) {
      return user.email;
    }
  });

  // console.log(selectDefaultValue);

  const selectedProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: _.compact(selectDefaultValue),
  };

  const selectedMtProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: selectedMTGroup,
  };

  let selectHostOwnerValue = permit?.hostSystemOwner?.email
  let selectDisciplineValue = permit?.discipline?.code

  const selectedHostOwnerProps = {
    style: { width: "100%" },
    value: selectHostOwnerValue,
  };

  const selectedDisciplineProps = {
    style: { width: "100%" },
    value: selectDisciplineValue,
  };

  let showActionBtn = false;
  if (status !== PermitStatus.Rejected) {
    if (authUser.role === UserRole.SuperAdmin) {
      // Show button for superadmin
      showActionBtn = true;
    } else if (authUser.role === UserRole.ContractorPM && status === PermitStatus.Pending) {
      // Show button for Contractor PM
      showActionBtn = true;
    } else if (authUser.role === UserRole.MTGroup && authUser._id !== permit?.submittedBy?._id && !approvedBy) {
      // Show button for MT Group
      showActionBtn = true;
    }
  }

  const ChildSingleSection = ({ section, submission = {} }) => {
    return (
      <div className="card full-details">
        <h2>{section.title}</h2>
        <div className="details-wrapper ">
          {section?.form?.map((item, key) => (
            <PermitItem key={key} item={item} submission={submission} />
          ))}
        </div>
      </div>
    );
  };

  const PermitItem = ({ item, submission }) => {
    const [visible, setVisible] = useState(false);

    const [getPresignedUrl, {data: PreSignedUrlData }] = useLazyQuery(GENERATE_PRESIGNED_URL);

    const fetchPresignedUrl = async (filename) => {
      // console.log("fetchPresignedUrl filename: " + filename);
      try {
        getPresignedUrl({ variables: { filename: filename } });
      } catch (error) {
        console.error("Error fetching pre-signed URL:", error);
        return null;
      }
    };

    const handleGetPresignedUrlData = async (filename) => {
      // console.log("handleGetPresignedUrlData: " + filename);
      fetchPresignedUrl(filename);
    };

    const handleUploadedFileOnclick = (filename) => {
      // console.log("handleUploadedFileOnclick: " + filename);
      handleGetPresignedUrlData(filename);
    }

    useEffect(() => {
      // console.log("useEffect PreSignedUrlData: " + JSON.stringify(PreSignedUrlData, null, 2));
      if(PreSignedUrlData && PreSignedUrlData?.GeneratePresignedUrl?.success) {
        const newWindow = window.open(PreSignedUrlData?.GeneratePresignedUrl?.url, '_blank');
        if (!newWindow) {
          console.error("Failed to open new window. It might be blocked by the browser.");
        }
      }
    }, [PreSignedUrlData]);

    return (
      <div className={`account-details-colum col-md-${item.col}`}>
        <h6>{item.label}</h6>
        {item.type === "file" ? (
          Array.isArray(submission[item.name]) ? (
            submission[item.name].map((filename) => {
              if (
                filename?.split(".").pop() === "jpg" ||
                filename?.split(".").pop() === "png" ||
                filename?.split(".").pop() === "jpeg"
              ) {
                return (
                  <img
                    alt="submission"
                    width="100"
                    src={formatImage(filename)}
                    onClick={() => setVisible(filename)}
                    style={{ cursor: "pointer" }}
                    className="mr-2 mb-2"
                  />
                );
              } else {
                return (
                  <div>
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        handleUploadedFileOnclick(filename)
                      }
                      } 
                      target="_blank"
                    >
                      <span>{filename}</span>
                    </a>
                    <hr />
                  </div>
                );
              }
            })
          ) : (
            "N/A"
          )
        ) : item.type === "esign" ? (
          submission[item.name] ? (
            <img
              alt="submission"
              width="100"
              src={submission[item.name]}
              onClick={() => setVisible(true)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            "N/A"
          )
        ) : item.type === "map" ? (
          Array.isArray(submission[item.name]) ? (
            submission[item.name].map((filename) => {
              if (
                filename?.split(".").pop() === "jpg" ||
                filename?.split(".").pop() === "png" ||
                filename?.split(".").pop() === "jpeg"
              ) {
                return (
                  <img
                    alt="submission"
                    width="100"
                    src={formatImage(filename)}
                    onClick={() => setVisible(filename)}
                    style={{ cursor: "pointer" }}
                    className="mr-2 mb-2"
                  />
                );
              } else {
                return (
                  <div>
                    <a href={formatImage(filename)} target="_blank">
                      <span>{filename}</span>
                    </a>
                    <hr />
                  </div>
                );
              }
            })
          ) : (
            "N/A"
          )
        ) : Array.isArray(submission[item.name]) ? (
          <p>{submission[item.name].map((val) => val + ", ")}</p>
        ) : item.type === "freetext" ? (
          <p> {submission[item.name]} </p>
        ) : (
          <p> {submission[item.name] || "N/A"}</p>
        )}

        {(item.type === "file" || item.type === "esign" || item.type === "map") && (
          <Modal title={item.label} visible={visible} onCancel={() => setVisible(false)} onOk={() => setVisible(false)}>
            <img alt="submission" src={item.type === "esign" ? submission[item.name] : formatImage(visible)} />
          </Modal>
        )}
      </div>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="create-permit-details">
            <div className="form-row">
            {permit && (
                <div className="form-group col-md-3">
                  Host/System Owner <br/>
                  <Select {...selectedHostOwnerProps} disabled />
                </div>
              )}
              {permit && (
                <div className="form-group col-md-3">
                  Discipline <br/>
                  <Select {...selectedDisciplineProps} disabled />
                </div>
              )}

              {permit && (
                <div className="form-group col-md-3">
                  Individual Reviewer(s) <br/>
                  <Select {...selectedProps} disabled />
                </div>
              )}

              {permit && (
                <div className="form-group col-md-3">
                  MTGroup(s) <br/>
                  <Select {...selectedMtProps} disabled></Select>
                </div>
              )}
            </div>
          </div>
          {!isChildren && (
            <div className="heading">
              <Link to={backURL} className="btn-link">
                <i className="far fa-arrow-left"></i> Back to view all child permit <span>[{form?.title}]</span>
              </Link>
            </div>
          )}

          {!isChildren && (
            <ChildUserInfo permit={permit} handleStatusUpdate={handleStatusUpdate} updateLoading={updateLoading} />
          )}

          {form?.sections?.map((section, key) => (
            <ChildSingleSection key={key} section={section} submission={permit?.submission} />
          ))}

          {/* {showUpdateDraftBtn && (
            <div className="account-details-btn">
              <CustomButton className="btn btn-primary">
                <Link to={`review/${permit?._id}`} className="btn-link" style={{ color: "white" }}>
                  Update Draft Permit
                </Link>
              </CustomButton>
            </div>
          )} */}

          {/* {permitted && (
            <div className="account-details-btn">
              <CustomButton type="submit" loading={updateLoading} onClick={handleSubmit} className="btn btn-primary">
                Update
              </CustomButton>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
