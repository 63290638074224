import gql from "graphql-tag";

export const GET_BLOCKED_WORDS_LIST = gql`
    query {
        GetBlockedWords {
            message
            success
            code
            count
            results {
                _id
                _word
            }
        }
    }   
`

export const GET_SINGLE_BLOCKED_WORD = gql`
    query($id: ID!) {
        GetSingleBlockedWord(id: $id) {
            code
            success
            message
            result {
                _id
                _word
            }
        }
    }
`;

export const CREATE_BLOCKED_WORD = gql`
    mutation($data: BlockedWordInput!) {
        AddBlockedWord(data: $data) {
            code
            success
            message
        }
    }
`;

export const DELETE_BLOCKED_WORD = gql`
    mutation($id: ID!) {
        DeleteBlockedWord(id: $id) {
            code
            success
            message
        }
    }
`;

export const UPDATE_BLOCKED_WORD = gql`
    mutation($id: ID!, $data: BlockedWordInput) {
        UpdateBlockedWord(id: $id, data: $data) {
                code
                success
                message
        }
    }
`;