import gql from "graphql-tag";

export const GET_DASHBOARD_CARDS_DATA = gql`
  query {
    GetDashboardCardsData {
      results
      message
      success
      code
    }
  }
`;

export const GET_DASHBOARD_COLLAPSE_DATA = gql`
  query {
    GetDashboardTableCollapseData {
      results
      message
      success
      code
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query {
    GetDashboardData {
      results
      message
      success
      code
    }
  }
`;

export const GET_CRITICAL_ACTIVITIES = gql`
  query ($permitId: String, $dateFrom: String, $dateTo: String) {
    GetCriticalActivities(permitId: $permitId, dateFrom: $dateFrom, dateTo: $dateTo) {
      results
      message
      success
      code
    }
  }
`;

export const GET_ENHANCED_DASHBOARD_DATA = gql`
  query ($permitSite: String, $currentWeek: String, $currentMonth: String, $currentYear: String) {
    GetEnhancedDashboardData(
      permitSite: $permitSite
      currentWeek: $currentWeek
      currentMonth: $currentMonth
      currentYear: $currentYear
    ) {
      message
      success
      code
      generalPermits
      sippPermits
      wahPermits
      hotWorkPermits
      confinedSpacePermits
      liftingPermits
      lssPermits
      totalPermits
      permitsData {
        permitSite
        data {
          permitType
          count
        }
      }
    }
  }
`;

export const GET_ENHANCED_DASHBOARD_DATA_1 = gql`
  query ($discipline: String, $currentWeek: String, $currentMonth: String, $currentYear: String) {
    GetEnhancedDashboardData1(
      discipline: $discipline
      currentWeek: $currentWeek
      currentMonth: $currentMonth
      currentYear: $currentYear
    ) {
      message
      success
      code
      generalPermits
      sippPermits
      wahPermits
      hotWorkPermits
      confinedSpacePermits
      liftingPermits
      lssPermits
      totalPermits
      permitsData {
        discipline
        disciplineTotal
        data {
          permitType
          count
        }
      }
    }
  }
`;

export const GET_ENHANCED_DASHBOARD_LOWER_DATA = gql`
  query ($permitSite: String, $currentWeek: String, $currentMonth: String, $currentYear: String) {
    GetEnhancedDashboardLowerData(
      permitSite: $permitSite
      currentWeek: $currentWeek
      currentMonth: $currentMonth
      currentYear: $currentYear
    ) {
      message
      success
      code
      totalPermits
      data {
        key
        month
        sippCount
        generalCount
        wahCount
        hotWorkCount
        confinedSpaceCount
        liftingCount
        lssCount
        monthTotal
      }
    }
  }
`;

export const GET_EPTW_INITIATIVES_DATA = gql`
  query ($permitSite: String, $currentWeek: String, $currentMonth: String, $currentYear: String) {
    GetEptwInitiativesData(
      permitSite: $permitSite
      currentWeek: $currentWeek
      currentMonth: $currentMonth
      currentYear: $currentYear
    ) {
      message
      success
      code
      totalPermits
      data {
        month
        f10a1Permits
        f10a2Permits
        f10nPermits
        f10nxPermits
        f10wPermits
        f10xPermits
        monthTotal
      }
    }
  }
`;

export const GET_DISCIPLINE_ENHANCED_DASHBOARD_DATA = gql`
  query ($discipline: String, $currentWeek: String, $currentMonth: String, $currentYear: String) {
    GetDisciplineEnhancedDashboardData(
      discipline: $discipline
      currentWeek: $currentWeek
      currentMonth: $currentMonth
      currentYear: $currentYear
    ) {
      message
      success
      code
      totalPermits
      permitsData {
        discipline
        disciplineTotal
        data {
          discipline
          count
        }
      }
    }
  }
`;

export const GET_DISCIPLINE_LOWER_GRAPH_DATA = gql`
  query ($discipline: String, $currentWeek: String, $currentMonth: String, $currentYear: String) {
    GetDisciplineLowerGraphData(
      discipline: $discipline
      currentWeek: $currentWeek
      currentMonth: $currentMonth
      currentYear: $currentYear
    ) {
      message
      success
      code
      data {
        discipline
        totalDiscipline
        data {
          key
          month
          count
        }
      }
      totalPermits
    }
  }
`;

export const GET_GRAPH_DATA = gql`
  query ($permitName: String, $permitSite: String, $currentWeek: String, $currentMonth: String, $currentYear: String) {
    GetGraphData(
      permitName: $permitName
      permitSite: $permitSite
      currentWeek: $currentWeek
      currentMonth: $currentMonth
      currentYear: $currentYear
    ) {
      message
      success
      code
      pendingApproval
      approved
      completed
      totalPermit
      days
    }
  }
`;

export const GET_B1_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB1Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
      totalHotWorks
    }
  }
`;

export const GET_B1_PERMITS_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB1Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
      totalHotWorks
    }
  }
`;

export const GET_B2_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB2Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_B2_PERMITS_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB2Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
    }
  }
`;

export const GET_B3_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB3Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_B3_PERMITS_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB3Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
    }
  }
`;

export const GET_B4_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB4Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
      totalWorkHeights
    }
  }
`;
export const GET_B4_PERMITS_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB4Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
      totalWorkHeights
    }
  }
`;

export const GET_B5_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB5Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_B5_PERMITS_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB5Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
    }
  }
`;

export const GET_B6_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB6Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_B6_PERMITS_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB6Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
    }
  }
`;

export const GET_B7_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB7Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_B7_PERMITS_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetB7Permits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
    }
  }
`;

export const GET_ENVIRONMENTAL_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetEnvironmentalPermits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_EXCAVATION_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetExcavationPermits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_HAZARDOUS_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetHazardousPermits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;
export const GET_TRAFFIC_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetTrafficPermits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_ALL_G_DASHBOARD = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetAllGeneralPermitsDashboard(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_ALL_G_DASHBOARD_COUNT = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetAllGeneralPermitsDashboard(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      count
    }
  }
`;